document.addEventListener("keydown", (event) => {
  if (event.key === 'Enter' && event.metaKey) {
    if (!document.activeElement) { return; }

    let closestForm = document.activeElement.closest('form');
    if (closestForm) {
      event.preventDefault();

      let submitButton = closestForm.querySelector('[type="submit"]:not([aria-hidden=true])') || closestForm.querySelector('button:not([aria-hidden=true])');
      if (submitButton) {
        submitButton.click();
      } else {
        closestForm.requestSubmit();
      }
    }
  }
});
