export class AjaxSpinnerLink {
  static SELECTOR = 'a.iconic-link[data-find-and-update]';
  static STASH_ATTR = 'data-ajax-spinner-old-classes';
  static FA_UTILITIES = 'fa fal fa-light far fa-regular fas fa-solid fat fa-thin fad fa-duotone fass fa-sharp fab fa-brands fa-1x fa-2x fa-3x fa-4x fa-5x fa-6x fa-7x fa-8x fa-9x fa-10x fa-border fa-flip-both fa-flip-horizontal fa-flip-vertical fa-fw fa-inverse fa-lg fa-li fa-pull-left fa-pull-right fa-spin-pulse fa-rotate-180 fa-rotate-270 fa-rotate-90 fa-sm fa-spin fa-stack fa-stack-1x fa-stack-2x fa-ul fa-xs'.split(' ');
  
  constructor(el) { this.el = el }
  get iconic() { return this.el.querySelector('.iconic') }
  
  spin() {
    this.el.classList.add('iconic-link---running');
    
    this.iconic.setAttribute(AjaxSpinnerLink.STASH_ATTR, this.iconic.className);
    let keepClasses = Array.from(this.iconic.classList)
      .filter(c => !c.startsWith('fa') || AjaxSpinnerLink.FA_UTILITIES.includes(c));
    this.iconic.className = ['fa-spinner fa-spin', ...keepClasses].join(' ');
  }
  
  restore() {
    this.el.classList.remove('iconic-link---running');
    
    let oldClasses = this.iconic.getAttribute(AjaxSpinnerLink.STASH_ATTR);
    if (oldClasses) {
      this.iconic.className = oldClasses;
    }
    this.iconic.removeAttribute(AjaxSpinnerLink.STASH_ATTR);
  }
}

window.addEventListener('DOMContentLoaded', () => {
  $(document)
    .on('ajax:beforeSend', AjaxSpinnerLink.SELECTOR, function(event) {
      new AjaxSpinnerLink(event.currentTarget).spin();
    })
    .on('ajax:complete', AjaxSpinnerLink.SELECTOR, function(event) {
      new AjaxSpinnerLink(event.currentTarget).restore();
    });
});
