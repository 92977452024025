window.addEventListener('DOMContentLoaded', function() {
  $(document).on('shown.bs.modal', function(event) {
    if ($(event.target).is('.no-autofocus')) return;
    
    let candidates = $(event.target).find('.btn-default.active input, :focusable:not(.btn-default input)').not('.close');
    let inputs = candidates.filter(':input');
    let foci = inputs.eq(0) ?? candidates.eq(0);
    
    // Focuses on the first object that is either an active button or just another focusable object
    foci.one('focus', function () {
      if (this.tagName !== 'TEXTAREA') return;

      this.selectionStart = 0;
      this.selectionEnd = this.value.length;
    }).focus();
  });
});
