window.addEventListener('DOMContentLoaded', function() {
  // keep popovers open if we're focused inside
  $(document).on('hide.bs.popover', function(event) {
    let $tip = $(event.target).data('bs.popover').tip();
  
    return $tip.find([
      ':focus',
      '.select2-container--focus',
      '.select2-container--open'
    ].join(', ')).length === 0;
  });

  // keep hover popovers open while we hover over them
  $(document).on('shown.bs.popover', function(event) {
    let tipObj = $(event.target).data('bs.popover');
    let tipEl = tipObj.tip();
    if (typeof(tipEl.data('rec.bs.keeparound')) !== 'undefined') return;
  
    tipEl.data('rec.bs.keeparound', false);
    tipEl.hover(function() {
      tipEl.data('rec.bs.keeparound', true);
    }, function() {
      tipEl.data('rec.bs.keeparound', false);
      tipObj.leave(tipObj);
    });
  }).on('hide.bs.popover', function(event) {
    let tip = $(event.target).data('bs.popover').tip();
    return !tip.data('rec.bs.keeparound');
  });
});
