import BusyBody from 'services/busy_body';

export class BootstrapInputGroup {
  static madeAddOn(el, side) {
    let html = el.dataset[`groupAddon${side}Html`];
    delete el.dataset[`groupAddon${side}Html`];
    if (html) { return Components.htmlTree(html).firstChild; }

    let text = el.dataset[`groupAddon${side}`];
    delete el.dataset[`groupAddon${side}`];
    if (!text) { return null; }
  
    return Components.tag('span', {class: 'input-group-addon'}, text);
  }

  constructor(input, {before, after, then}={}) {
    let beforeEl = before || BootstrapInputGroup.madeAddOn(input, 'Before');
    let afterEl = after || BootstrapInputGroup.madeAddOn(input, 'After');
  
    let groupEl = Components.tag('div', {class: 'input-group'});
    input.before(groupEl)
    
    groupEl = Components.tag(groupEl, beforeEl, input, afterEl);
    then?.call(groupEl, groupEl);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  new BusyBody({
    selector: [
      'input[data-group-addon-before]',
      'input[data-group-addon-after]',
      'input[data-group-addon-before-html]',
      'input[data-group-addon-after-html]'
    ].join(', '),
    added: (n) => {new BootstrapInputGroup(n)},
  });
});
