export default class Glow {

  constructor(el, labelText, colorName) {
    this._el = el;
    this._labelText = labelText;
    this._colorName = colorName;
    this._cssClasses = 'glowing glow-' + this._labelText.toLowerCase();
    this._label = this._makeLabel(labelText, 'label-' + colorName);
    this._styles = this._makeStyles();
  }

  el() {
    return this._el;
  }

  frame() {
    const glow = this;
    return this._frame = this._frame || (() => {
      let frames = $();
      glow._el.get().forEach(el => {
        const frame = $(el).closest('.glow-frame');
        frames = frames.add(frame.length ? frame : $(el));
      })
      return frames;
    })();
  }

  color(alpha) {
    const rgb = {
      danger: '255, 170, 170',
      warning: '240, 173, 78',
      success: '204, 238, 153',
      info: '91, 192, 222'
    }[this._colorName] || this._colorName;
    return 'rgba('+ rgb + ", " + alpha + ")";
  }

  animate() {
    const glow = this;
    const frame = this.frame();
    return new Promise(resolve => {
      if (frame.length === 0) return resolve(glow);

      glow._analytics('start');
      this._whenAllTransitionsAreComplete(() => {
        glow._analytics('end');
        resolve(glow);
      });

      this._animateToBaseline(frame);
      this._animateToGlow(frame);
      this._animateLabel();
    })
  }

  animateLoop(loops, delay) {
    if (loops < 1) return;

    const glow = this;
    glow.animate().then(() => {
      setTimeout(() => {
        glow.animateLoop((loops || 1000) - 1, delay);
      }, delay || 1000)
    });
  }

  _analytics(action, id) {
    const glow = this;
    this.frame().each(frame => {
      analytics.event('glow', action, id || glow._analytics_id_for($(frame)));
    });
  }

  _analytics_id_for($frame) {
    const id = $frame.attr('analytics-id') || $frame.attr('id');
    const label = this._labelText.toLowerCase();
    return id ? ("#" + id + ": " + label) : label;
  }

  _animateLabel() {
    const glow = this;
    glow._label.prependTo(glow._el).fadeOut(1000, () => {$(this).remove()});
  }

  _whenAllTransitionsAreComplete(fn) {
    const all = [];
    this.frame().each((ix, f) => {
      const d = $.Deferred();
      $(f).data('glow-complete', d);
      all.push(d);
    });
    $.when.apply($, all).then(fn);
  }

  _animateToBaseline(frame) {
    const glow = this;
    glow._el.css({position: "relative"});
    frame
      .css(glow._styles.baseline)
      .addClass(glow._cssClasses);
  }

  _animateToGlow(frame) {
    const glow = this;

    // if you do this immediately, `transitionend` will not trigger consistently:
    // https://drafts.csswg.org/css-transitions/#transitionend
    setTimeout(() => {
      glow._analytics('glow');
      frame
        .one('transitionend', function() {glow._animateToFade($(this))})
        .css(glow._styles.glow);
    }, 0);
  }

  _animateToFade(frame) {
    const glow = this;
    glow._analytics('fade');

    frame
      .one('transitionend', function() {glow._animateToCleanup($(this))})
      .css(glow._styles.fade)
      .removeClass(glow._cssClasses);
  }

  _animateToCleanup(frame) {
    // Safari will sometimes leave a border artifact; force a redraw to clean it up
    const glow = this;
    glow._analytics('cleanup');

    frame
      .one('transitionend', function() {glow._animateToReset($(this))})
      .css(glow._styles.cleanup)
      .data('glow-complete').resolve();
  }

  _animateToReset(frame) {
    // without this, the outline won't show on subsequent runs
    const glow = this;

    // if you do this immediately, the animation jerks
    setTimeout(() => {
      glow._analytics('reset');
      frame.css(glow._styles.reset);
    }, 0);
  }

  _makeLabel(text, cssClass) {
    return $('<div class="label glow-label" />')
      .addClass(cssClass)
      .text(text);
  }

  _makeStyles() {
    return {
      baseline: {
        transition: '0ms',
        background: 'rgba(255,255,255,0)',
        outline: '0px solid '+this.color(0)
      },
      glow: {
        transition: '500ms',
        background: this.color(0.2),
        outline: '3px solid '+this.color(1)
      },
      fade: {
        transition: '500ms',
        background: 'rgba(255,255,255,0)',
        outline: '0.1px solid rgba(255,255,255,0)'
      },
      cleanup: {
        boxShadow: '0 0 0px white'
      },
      reset: {
        background: '',
        outline: '',
        boxShadow: ''
      }
    }
  }

  static make(el, labelText, colorName) {
    return el.length ? new Glow(el, labelText || "", colorName || 'success').animate() : Promise.resolve();
  }

  static created(el, labelText, colorName) {
    return Glow.make(el, labelText || "CREATED", colorName || 'success');
  }

  static updated(el, labelText, colorName) {
    return Glow.make(el, labelText || "UPDATED", colorName || 'success');
  }

  static destroyed(el, labelText, colorName) {
    return Glow.make(el, labelText || "REMOVED", colorName || 'danger');
  }

  static highlight(el, labelText, colorName) {
    return Glow.make(el, labelText || "", colorName || 'warning');
  }
}

window.Glow = Glow;

document.addEventListener('click', (event) => {
  const glowLabel = event.target.closest('*[data-glow-label]');
  if (glowLabel) {
    const $trigger = $(glowLabel);
    const glowTarget = $trigger.data('glow-target') ? $($trigger.data('glow-target')) : $trigger;
    new Glow(glowTarget, $trigger.data('glow-label'), $trigger.data('glow-color') || 'info').animate();
  }

  const glowToggle = event.target.closest('*[data-toggle="glow"], button[data-toggle="glow"]');
  if (glowToggle) {
    let $this = $(glowToggle);
    $this = $this.attr('data-target') ? $this : $(this);
    new Glow($($this.attr('data-target')), $this.data('glow-target-label') || "", $this.data('glow-color') || 'warning').animate();
  }

  const glowTrigger = event.target.closest('*[data-glow-target]');
  if (glowTrigger) {
    const $glowTrigger = $(glowTrigger);
    const $glowTarget = $($glowTrigger.attr('data-glow-target'));
    const goGlow = () => new Glow($glowTarget, $glowTrigger.data('glow-target-label') || "", $glowTrigger.data('glow-color') || 'warning').animate();
    const $modal = $glowTarget.closest('.modal')
    $modal.length ? $modal.one('transitionend', goGlow) : goGlow();
  }
});
