/*
  Telescope adds scopes (classes) from far away places.

  You use it like this:

      <!-- a trigger -->
      <select data-telescope-target='#target'>
        <option value='1'>A</option>
        <option value='2'>B</option>
        <option value='3'>C</option>
      </select>
      
      <!-- a target -->
      <div id='target' data-telescope-map='{"1": "needs-more-money needs-to-rock-out", "2": "needs-more-money"}'>
        <div class='hidden-unless-needs-more-money'>Hey, you need a more money!</div>
        <div class='shown-if-needs-to-rock-out'>Listen to the beat, man!</div>
      </div>

  When you pick from the `<select>`, Telescope jumps into action:

    1. It finds the target
    2. It fires off a `will-change.telescope` event
    3. It removes all the scopes that *any* value might set
    4. It adds in the scopes that the *new* value requires
    5. It fires off a `did-change.telescope` event

  The scopes are added as classes, so your CSS can adapt to the new state using normal rules.
  
  But Telescope also has some default behavior for the most used cases:

    1. It looks inside the target for things with classes like `xxx-unless-scopename` and, hides, disables, etc as appropriate
    2. It fires off analytics so you know which scopes are actually seeing use
  
  If you want to handle something more complicated, try to do it in a `did-change.telescope` listener instead of bolting behavior in here.
*/

let idCounter = 1;

export class Telescope {
  constructor(target) {
    this.targetSelector = $(target).selector;
    this._loadTarget();
  }

  // internal helpers
  _loadTarget() { // useful if the target changes its attributes
    this._target = $(this.targetSelector);
    this._map = JSON.parse(this._target.attr('data-telescope-map') || '{}');
    this._allScopes = this._findAllScopes();
  }
  
  _findAllScopes() { // find what scopes telescope should control
    let fromData = this._target.attr('data-telescope-scopes');
    return fromData || $.trim($.map(this._map, function(scopes, value) {
      return scopes;
    }).join(' '));
  }
  
  _asElement(thing) {
    thing = thing.join ? thing.join(' ') : thing;
    thing = thing.split ? $('<div>').addClass(thing) : thing;
    return thing;
  }
  
  _asClassArray(thing) {
    thing = thing.attr ? (thing.attr('class') || '') : thing;
    thing = thing.split ? $.trim(thing).split(/\s+/) : thing;
    return thing;
  }
  
  _scopeState(from) { // return an object with all scopes as keys and true/false for enabled/disabled
    from = this._asElement(from);
    let state = {};
    for (const scope of this._allScopes.split(/\s+/)) {
      if (scope) {
        state[scope] = from.is('.' + scope);
      }
    }
    return state;
  }
  
  _applyScopes(newScopes, onElement) { // remove allScopes and add these new ones
    return onElement
      .removeClass(this._allScopes)
      .addClass(newScopes.join(" "));
  }
  
  _uniqueScopes(scopes) { // get a unique'd array of the valid scopes
    let allScopes = this._allScopes;
    scopes = this._asClassArray(scopes);
    return scopes.filter(function(scope, ix, self) {
      return allScopes.indexOf(scope) > -1 && scopes.indexOf(scope) === ix
    }).concat('telescope-initialized').sort();
  }
  
  _scopesAddedBetween(before, after) { // return scopes in after that aren't in before
    return this._uniqueScopes(after.filter(function(scope) {
      return before.indexOf(scope) === -1;
    }));
  }
  
  // accessors
  target() {
    return this._target;
  }
  
  allScopes() { // what scopes does telescope control?
    return this._allScopes;
  }
  
  scopes(newScopes) { // without arg, what scopes are enabled; with arg, what scopes would be enabled if these are added
    if (arguments.length === 0) {
      return this._uniqueScopes(this._target);
    } else {
      let standin = $('<div>').addClass(this._target.attr('class'));
      return this._uniqueScopes(this._applyScopes(newScopes, standin));
    }
  }
  
  scopesForValue(rawValue) { // find the scopes associated with an unmapped value
    return this._uniqueScopes(this._map[rawValue] || '');
  }
  
  event(name, value) {
    analytics.event('telescope', name, this.targetSelector, value);
  }
  
  // the workhorse
  val(rawValue) { // set the value and change scopes as appropriate
    let timestamp = this._target.data('telescope-timestamp');
    if (timestamp && Telescope._timestamp === timestamp) {
      this._target.trigger('will-not-change.telescope', [this]);
      return;
    } else {
      Telescope._preventLoops((timestamp) => {
        this._target.data('telescope-timestamp', Telescope._timestamp);
    
        let scopes = {rawValue: rawValue, toAdd: this.scopesForValue(rawValue)};
        scopes.all = this.allScopes();
        scopes.old = this.scopes();
        scopes.new = this.scopes(scopes.toAdd.concat(['telescope-initialized']));
        scopes.state = this._scopeState(scopes.toAdd);
        scopes.added = this._scopesAddedBetween(scopes.old, scopes.new);
        scopes.removed = this._scopesAddedBetween(scopes.new, scopes.old);
        
        if (scopes.added.length > 0 || scopes.removed.length > 0) {
          this._target.trigger('will-change.telescope', [this, scopes]);
          this._applyScopes(scopes.toAdd, this._target);
          this._target.trigger('did-change.telescope', [this, scopes]);
        } else {
          this._target.trigger('will-not-change.telescope', [this]);
        }
        setTimeout(() => {
          this._target.trigger('finished-change.telescope', [this, scopes]);
        }, 500);
      });
    }
  }
  
  static _preventLoops(fn) {
    if (Telescope._timestamp) {
      fn(Telescope._timestamp);
    } else {
      Telescope._timestamp = new Date().getTime();
      fn(Telescope._timestamp);
      delete Telescope._timestamp;
    }
  }
  
  static useNewValue(trigger, target) {
    if (trigger?.classList?.contains("telescope-ignore")) return;

    let $trigger = $(trigger);
    
    let val = $trigger.data('telescope-value') || $trigger.val();
    if ($trigger.is(':checkbox') && !$trigger.is(':checked')) {
      val = $trigger.data('telescope-value-unchecked') || 'off';
    }
    if ($trigger.is('select')) {
      val = $trigger.find('option:selected').data('telescope-value') || val;
    }
    
    if (!target && $trigger.data('telescope-initial-value')) {
      trigger.id = trigger.id || `telescope-target-${idCounter++}`;
      target = `#${trigger.id}`;
      val = $trigger.data('telescope-initial-value');
      $trigger.removeAttr('data-telescope-initial-value');
    }
    
    new Telescope(target || $trigger.closest('[data-telescope-target]').attr('data-telescope-target')).val(val);
  }
  
  static useCurrentValues(root) {
    Telescope.busyBody?.visit({addedNodes: [root]});
  }
}

window.addEventListener('DOMContentLoaded', function() {
  window.Telescope = Telescope;
  
  $(document).on('change.telescope', '[data-telescope-target]', function(event) {
    Telescope.useNewValue(event.target);
  });

  $(document).on('click.telescope', 'a[data-telescope-target], button[data-telescope-target]', function(event) {
    event.preventDefault();

    Telescope.useNewValue(event.currentTarget);
  });

  // fire off analytics when scopes are added or removed
  $(document).on('did-change.telescope', function(event, telescope, scopes) {
    if (event.namespace !== 'telescope') { return }

    if (scopes.rawValue) { telescope.event(scopes.rawValue); }
    scopes.added.forEach(s => s && s !== 'telescope-initialized' && telescope.event(`+${s}`));
    scopes.removed.forEach(s => s && s !== 'telescope-initialized' && telescope.event(`-${s}`));
  });

  // change the DOM based on scope-associated class names
  $(document).on('did-change.telescope', function(event, telescope, scopes) {
    if (event.namespace !== 'telescope') { return }

    let setEnabled = function($on, state) {
      return $on.prop('disabled', state).toggleClass('disabled', state);
    };

    let $this = $(event.target);
    for (const [scope, is] of Object.entries(scopes.state)) {
      // before you add that thing, consider doing it in a listener of your own
      setEnabled($this.find('.disabled-unless-' + scope + ', .enabled-if-' + scope), !is)
        .add(setEnabled($this.find('.disabled-if-' + scope + ', .enabled-unless-' + scope), is))
        .each(function() {Telescope.useCurrentValues(this)});
      $this.find('.disappear-unless-' + scope + ', .appear-if-' + scope).toggleClass('hidden', !is).toggleClass('shown', is);
      $this.find('.disappear-if-' + scope + ', .appear-unless-' + scope).toggleClass('hidden', is).toggleClass('shown', !is);
      $this.find('.hidden-unless-' + scope + ', .shown-if-' + scope)[is ? 'slideDown' : 'slideUp']();
      $this.find('.hidden-if-' + scope + ', .shown-unless-' + scope)[!is ? 'slideDown' : 'slideUp']();
      $this.find('.checked-if-' + scope + ', .unchecked-unless-' + scope).prop('checked', is).trigger('change.telescope');
      $this.find('.unchecked-if-' + scope + ', .checked-unless-' + scope).prop('checked', !is).trigger('change.telescope');
    }
  });

  // set focus to one of the just-revealed elements, if any scope needs to
  $(document).on('did-change.telescope', function(event, telescope, scopes) {
    if (event.namespace !== 'telescope') { return }

    let focusMap = JSON.parse(telescope.target().attr('data-telescope-focus') || '{}');

    for (const scope of scopes.new) {
      let focusOn = $(focusMap[scope]);
      if (focusOn.is('.select2-offscreen')) {
        return focusOn.select2('open');
      } else if ($(focusOn).is(':visible')) {
        return $(focusOn).focus();
      }
    }
  });
  
  // prime the pump so we don't have to figure out state server-side and client-side
  Telescope.busyBody = new BusyBody({
    selector: [
      "[data-telescope-map][data-telescope-initial-value]",
      "[data-telescope-target]:not(a, button, [type=radio]):not(:disabled)",
      "[data-telescope-target][type=radio][checked]:not(:disabled)",
      "[data-telescope-target] *[type=radio][checked]:not(:disabled)",
    ].join(', '),
    added: (el) => Telescope.useNewValue(el),
  });
});
