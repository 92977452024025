import {RemotePicker} from 'pickers/picker';

export class SearchSourcePicker extends RemotePicker {
  get url() { return '/api/pickers/search_sources.json' }
  get resultsKey() { return 'search_sources' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Type a search source',
  }}
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      tags: true,
      selectOnClose: true,
    };
  }
  
  processResult(searchSource) {
    return {text: searchSource.name, id: searchSource.id};
  }
}
SearchSourcePicker.watch('.search-source-picker');
