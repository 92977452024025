import {RemotePicker} from 'pickers/picker';

class FieldOfStudyPicker extends RemotePicker {
  get url() { return '/api/pickers/fields_of_study.json' }
  get resultsKey() { return 'fields_of_study' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Type a field of study',
  }}
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      maximumSelectionLength: 40, // Recruitment::MAX_FIELDS_OF_STUDY,
      containerCssClass: "select2-container-valign-middle",
    };
  }
  
  get ajaxOptions() {
    return {
      ...super.ajaxOptions
    };
  }
  
  ajaxData(params) {
    return {
      ...super.ajaxData(params),
      diversity_data_set_id: this.el.dataset.diversityDataSetId,
    };
  }
  
  templateSelection(item) {
    var groupName = item.data_source;
    return groupName ? groupName + ": " + item.text : item.text || this.strings.placeholder;
  }
}
FieldOfStudyPicker.watch('.field-of-study-picker');
