import {Picker} from 'pickers/picker';

class LinkLabelPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: 'Choose or create label',
  }}
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      tags: true,
    };
  }
}
LinkLabelPicker.watch('.link--label-picker');
