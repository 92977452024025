export var Once = {
  disable($this) {
    let replacedBy = $this.clone().addClass('once-placeholder');
    let newText = $this.attr('data-active-text');
    let link = $this.is('a');

    $this.add(replacedBy).data('once.replaced_by', replacedBy);

    if (link) {
      replacedBy.addClass('looks-disabled').attr('href', '');
    } else {
      replacedBy.prop('disabled', true);
    }

    ({
      input: () => { replacedBy.val(newText || $this.val()) },
      button: () => { replacedBy.html(newText || $this.html())},
      a: () => {}
    }[$this[0].tagName.toLowerCase()])();

    $this.after(replacedBy);
    $this.toggleClass('once-hidden', true);

    $this.add(replacedBy).on('reset.once', () => Once.reset($this));
    if (link) {
      setTimeout(() => $this.trigger('reset.once'), Number($this.data('once-autoreset') || 1000));
    }
  },
  
  reset($this) {
    let replacedBy = $this.data('once.replaced_by');
    $this.removeClass('once-hidden');
    if (replacedBy) {
      replacedBy?.remove();
    } else {
      let form = $this.closest('form');
      $(form).find('.once-placeholder').remove();
    }
  },
  
  resetWithin(within) {
    $(within).find('.once').trigger('reset.once');
  }
};

window.addEventListener('DOMContentLoaded', function() {
  window.Once = Once;

  document.addEventListener('click', event => {
    let target = event.target.closest('.once');
    if (target) {
      Once.disable($(target));
    }
  })
});
