import {RemotePicker} from 'pickers/picker';

class DisciplinePicker extends RemotePicker {
  get url() { return '/api/pickers/disciplines.json' }
  get resultsKey() { return 'disciplines' }

  get pickerOptions() {
    return {
      ...super.pickerOptions,
      selectOnClose: false,
      minimumInputLength: 0,
      maximumSelectionLength: 20,
      insertTag: (data, tag) => {data.push(tag)},
    };
  }

  templateSelection(state) {
    return state.id ? this.templateResult(state) : this.strings.placeholder;
  }

  templateResult(state) {
    if (!state.id) return state.text;

    let codeComponent = (state.text === 'Not asked' || state.text === 'Not applicable') ? "" : Components.tag('span', state.code || state.element?.dataset?.code, {class: 'discipline--code'});

    return Components.tag('div', {class: "discipline"},
      codeComponent,
      Components.tag('strong', state.text, {class: 'discipline--title'}),
    );
  }
}
DisciplinePicker.watch('.discipline-picker');
