// this file powers the modal editors used for inline editing
import {FindAndUpdate} from 'services/find_and_update';

class ModalEditor {
  constructor(root) {
    this.root = root;
  }
  
  show() {
    $(this.root).modal('show');
  }
  
  hide() {
    $(this.root).modal('hide');
  }
  
  static open(selector, opts) {
    let modal = $(selector);
    if (modal.length === 1) {
      modal.modal('show');
    }
  }
  
  static find(modal) {
    return $(modal).is('.modal') ? $(modal) : $(modal).find('.modal');
  }
  
  static ajax(form, settings) {
    return $.ajax(settings)
      .done(function(json, status, xhr) {
        ModalEditor.analytics(form, 'editor-success');
        ModalEditor.ajaxSuccess(form, json, status, xhr);
      })
      .fail(function(xhr, errorname, error) {
        ModalEditor.analytics(form, 'editor-failure');
        ModalEditor.ajaxError(form, xhr.responseJSON || JSON.parse(xhr.responseText), errorname || error?.message, xhr);
      });
  }
  
  static ajaxSuccess(modal, json, status, xhr) {
    if (json.updates) {
      FindAndUpdate.all(json).then(function() {
        FindAndUpdate.glowFromData(modal);
        Once.resetWithin(modal);
      });
    } else {
      new DynamicErrorArea(modal[0]).fill(json);
    }
  }
  
  static ajaxError(modal, json, status, xhr) {
    if (status && !json) {
      ModalEditor.analytics(modal, 'error-substitution');
      json = {error_message: status};
    }
    
    new DynamicErrorArea($(modal)[0]).fill(json);

    if (json && json['updates']) {
      FindAndUpdate.htmlImmediate(json.updates);
      Once.resetWithin(modal);
    }
  }
  
  static analytics(modal, event) {
    modal = ModalEditor.find(modal);
    analytics.event('modals', event, modal.attr('data-analytics-id') || modal.attr('id'));
  }
}

window.ModalEditor = ModalEditor;
window.addEventListener('DOMContentLoaded', function() {
  document.addEventListener("ajax:success", event => {
    if (event.target.matches("form.modal-editor")) {
      ModalEditor.ajaxSuccess($(event.target), ...event.detail);
    }
  });

  document.addEventListener("ajax:error", event => {
    if (event.target.matches("form.modal-editor")) {
      ModalEditor.ajaxError($(event.target), ...event.detail);
    }
  });
  
  setTimeout(() => ModalEditor.open((document.location.hash || 'match-nothing') + ".modal"), 1);
});
