export class Reindexer {
  constructor({within, groupName, groupBy, groupCallback}) {
    this.within = within;
    this.groupName = groupName;
    this.groupBy = groupBy;
    this.groupCallback = groupCallback || function() {};
  }
  
  get groups() {
    return this.within.querySelectorAll(this.groupBy);
  }
  
  reindex(startAt=0) {
    let groups = this.groups;
    
    for (let ix = 0; ix < groups.length; ++ix) {
      let group = groups[ix];
      
      this.reindexGroup(group, ix + startAt);
    }
    
    return groups.length + startAt;
  }
  
  reindexGroup(group, ix) {
    this.groupCallback(group, ix);
    
    this.reindexAttributes(group, 'name',
      new RegExp(`${this.groupName}_attributes\\]\\[\\d+\\]`),
      `${this.groupName}_attributes][${ix}]`);
    this.reindexAttributes(group, 'id',
      new RegExp(`${this.groupName}_attributes_\\d+_`),
      `${this.groupName}_attributes_${ix}_`);
    this.reindexAttributes(group, 'for',
      new RegExp(`${this.groupName}_attributes_\\d+_`),
      `${this.groupName}_attributes_${ix}_`);
  }
  
  reindexAttributes(group, attrName, pattern, sub) {
    for (let el of group.querySelectorAll(`[${attrName}]`)) {
      let oldValue = el.getAttribute(attrName);
      let newValue = oldValue.replace(pattern, sub);
      el.setAttribute(attrName, newValue);
    }
  }
}

window.Reindexer = Reindexer;
