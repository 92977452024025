import {Picker} from 'pickers/picker';

class CategoryPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: this.el.getAttribute('placeholder') || 'Choose a category',
  }}
  
  templateSelection(obj) {
    let optGroup = this.templateOptGroup(obj);
    return ((optGroup) ? `${optGroup}: ${obj.text}` : obj.text) || this.strings.placeholder;
  }
}
CategoryPicker.watch('.category-picker');
