import {Picker} from 'pickers/picker';

class JustAPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: this.placeholder,
  }}
  
  get placeholder() {
    let selector = Object.keys(JustAPicker.placeholdersBySelector).find(s => this.el.matches(s));
    return JustAPicker.placeholdersBySelector[selector] || super.strings.placeholder;
  }
  
  static placeholdersBySelector = {
    '.committee-role-selector, .approver-role-picker, select.role-picker': 'Choose a role',
    '.school-picker': 'Choose a school',
    'select.cto-picker': 'Type a CTO',
    '.just-a-picker': 'Please make a selection',
  }
}
JustAPicker.watch(Object.keys(JustAPicker.placeholdersBySelector).join(', '));
