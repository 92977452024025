import { FindAndUpdate } from '../../services/find_and_update';
import { Cosmopolitan } from '../../cosmopolitan';

export default class StatusModal {
  static autoOpen() {
    let applicationId = document.location.hash.match(/^#update-status-for-(\d+)$/);
    if (applicationId) setTimeout(() => StatusModal.openLink(applicationId[1]), 1);
  }
  
  static openLink(applicationId) {
    let links = document.querySelectorAll(`.to-update-status[data-application-id="${applicationId}"]`);
    if (links.length) {
      links[0].dataset.includeUpdates = JSON.stringify(
        Array.from(links).reduce((arr, link) => [...arr, ...JSON.parse(link.dataset.includeUpdates || '[]')], [])
      );
      links[0].click();
    }
  }
  
  static open(applicationId, {includeUpdates}) {
    StatusModal.remove();
    
    fetch(`/analyst/applications/${applicationId}/statuses.json`)
      .then(response => response.json())
      .then(json => {
        let modal = Components.htmlTree(json.modal).children[0];
        if (includeUpdates) {
          modal.querySelector('.status_form_include_updates input').value = includeUpdates;
        }
        document.body.append(modal);
        
        $(`#update-status-for-${applicationId}`)
          .modal('show')
          .on('hidden.bs.modal', () => StatusModal.remove());

        FindAndUpdate.cosmopolitan(['appointments/editor']);
      })
      .catch(e => console.error(`Could not open status modal: ${e}`, e));
  }
  
  static remove() {
    document.querySelector('.update-status-form')?.remove();
  }
}

window.addEventListener('DOMContentLoaded', () => {
  Cosmopolitan.load("form/pending_employee_id");

  document.addEventListener('click', event => {
    let trigger = event.target;
    
    if (trigger.matches('.to-update-status')) {
      StatusModal.open(trigger.dataset.applicationId, {includeUpdates: trigger.dataset.includeUpdates});
    } else if (trigger = trigger.closest('.edit-appointment-via-offer')) {
      StatusModal.openLink(trigger.dataset.applicationId);
    }
  });
  StatusModal.autoOpen();
  
  $(document).on("ajax:error", '.update-status-form', function(event) {
    ModalEditor.ajaxError($(this), ...event.detail);
  });
});
