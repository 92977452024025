import BusyBody from 'services/busy_body';

window.addEventListener('DOMContentLoaded', function() {
  new BusyBody({
    selector: '*[data-toggle=tooltip]',
    added: (el) => {$(el).tooltip()},
  });
  
  new BusyBody({
    selector: '*[data-toggle=popover], .help-popover',
    added: (el) => {$(el).popover()},
  });
  
  new BusyBody({
    selector: 'abbr[title]:not(.with-tooltip)',
    added: (el) => {
      var tooltipText = $(el).attr('title') || $(el).attr('data-original-title');
      if (!tooltipText) return;

      $(el).addClass('with-tooltip').tooltip({content: tooltipText});
    },
  });
});
