import BusyBody from 'services/busy_body';

export class AriaForms {
  constructor(root) {
    this.root = root ?? document;
  }
  
  add() {
    this.addRequired();
    this.addErrors();
  }
  
  addRequired() {
    this.root.querySelectorAll('.controls .required').forEach(e => e.ariaRequired = true);
  }
  
  addErrors() {
    this.root.querySelectorAll('.help-block.error').forEach(e => {
      let controls = [];
      
      // help-block class not alwyas on the error sometimes on the parent element
      // this happens to be true for form fields that are not in Ged wizards
      let helpBlock = e.closest('.help-block') ?? e;
      let primaryControl = helpBlock.previousElementSibling;
      controls.unshift(primaryControl);
      
      if (primaryControl.classList.contains('select2')) {
        // handle select2 editors
        
        let select2Container = primaryControl;
        let select = primaryControl.closest('.select2-container').previousElementSibling;
        if (select) {
          primaryControl = select;
          controls.unshift(select);
        }
        
        // don't read placeholders twice
        let placeholder = primaryControl.querySelector('.select2-selection__placeholder');
        if (placeholder) { placeholder.ariaHidden = true; }
        
        // find the real interactive form elements
        controls.push($(primaryControl).data('select2')?.$dropdown?.find('input')[0]); // search box
        controls.push(select2Container.querySelector('.select2-selection'));           // selection box
      } else if (primaryControl.classList.contains('input-group')) {
        // handle controls with add ons, like dates and salaries
        let control = primaryControl.querySelector('input, select, textarea');
        if (control) { controls = [primaryControl = control]; }
      }
      
      helpBlock.id = `${primaryControl.id}-error-description`;
      controls.filter(c => c).forEach(control => {
        control.ariaInvalid = true;
        control.setAttribute('aria-describedby', helpBlock.id);
      });
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new BusyBody({
    selector: 'form, [data-ged] .editor',
    added: (e) => new AriaForms(e).add(),
  });
  
  // select2 pickers might not have loaded when BusyBody triggers; make sure we annotate when they open
  $(document).on("select2:open", (event) => {
    new AriaForms(event.target.closest('.form-group, form')).add();
  });
});
