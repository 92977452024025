export class FindAndUpdate {
  static all(updates) {
    return new Promise((resolve) => {
      FindAndUpdate.html(updates.updates).then(function() {
        FindAndUpdate.classes(updates.classes);
        FindAndUpdate.text(updates.text_updates);
        FindAndUpdate.disables(updates.disables);
        FindAndUpdate.glow(updates.glows);
        FindAndUpdate.cosmopolitan(updates.cosmopolitan);
        FindAndUpdate.#bubbleEvent(document.body, {changed: 'all', changes: updates});
        resolve(updates);
      });
    });
  }
  
  static whenModalCloses() {
    return new Promise((resolve) => {
      let currentModal = document.querySelector(".modal.in");
      if (currentModal) {
        $(currentModal).
          one('hidden.bs.modal', resolve).
          modal('hide');
      } else {
        resolve();
      }
    });
  }
  
  static #bubbleEvent(el, detail) {
    el.dispatchEvent(new CustomEvent("find_and_update", {bubbles: true, cancelable: true, detail: detail}));
  }
  
  static #transform(updates, detail, fn) {
    Object.entries(updates || {}).forEach(([selector, content]) => {
      document.querySelectorAll(selector).forEach(el => fn(el, content, selector));
      detail && document.querySelectorAll(selector).forEach(el => FindAndUpdate.#bubbleEvent(el, {...detail, changes: updates}));
    });
    return Promise.resolve();
  }
  
  static text(updates) {
    return FindAndUpdate.#transform(updates, {changed: "text"}, (el, content) => el.innerText = content);
  }
  
  static htmlImmediate(updates) {
    return FindAndUpdate.#transform(updates, {changed: "html"}, (el, content) => el.outerHTML = content);
  }
  
  static html(updates) {
    return FindAndUpdate.whenModalCloses().then(function() {
      FindAndUpdate.htmlImmediate(updates);
    });
  }
  
  static disables(updates) {
    return FindAndUpdate.#transform(updates, {changed: "disables"}, (el, content) => el.disabled = content);
  }

  static glow(glows) {
    glows = glows || {
      created: '.target-of-create',
      updated: '.target-of-update',
      destroyed: '.target-of-destroy'
    };
    
    let created = Glow.created($(glows.created))
      .then(g => g?.el()?.removeClass('target-of-create'));
    let updated = Glow.updated($(glows.updated))
      .then(g => g?.el()?.removeClass('target-of-update'));
    let destroyed = Glow.destroyed($(glows.destroyed))
      .then(g => g?.el()?.removeClass('target-of-destroy'));
    
    return Promise.all([created, updated, destroyed]);
  }
  
  static glowFromData(element) {
    $(element).parents().addBack().each(function(ix, el) {
      $($(el).data('created-glow-target')).addClass("target-of-create");
      $($(el).data('updated-glow-target')).addClass("target-of-update");
      $($(el).data('destroyed-glow-target')).addClass("target-of-destroy");
    });
    return FindAndUpdate.glow();
  }
  
  static classes(updates) {
    let modifyClasses = function(el, list, mutator) {
      (list || '').split(' ').filter(c => c).forEach(c => {
        let had = el.classList.contains(c);
        el.classList[mutator](c);
        if (mutator === 'add') {
          had || FindAndUpdate.#bubbleEvent(el, {changed: 'classes.added', changes: updates});
        } else if (mutator === 'remove') {
          had && FindAndUpdate.#bubbleEvent(el, {changed: 'classes.removed', changes: updates});
        }
      });
    };
    
    return FindAndUpdate.#transform(updates, null, (el, classes) => {
      modifyClasses(el, classes.removeEarly, 'remove');
      modifyClasses(el, classes.add, 'add');
      modifyClasses(el, classes.remove, 'remove');
    });
  }
  
  static cosmopolitan(scripts) {
    if (scripts) { document.body.dataset.cosmopolitan = scripts.join(' '); }
    return Promise.resolve();
  }
};

window.FindAndUpdate = FindAndUpdate;
window.addEventListener('DOMContentLoaded', () => {
  function handleAjax(kind, callback) {
    document.addEventListener('ajax:success', (event) => {
      if (event.target.matches(`[data-find-and-update~="${kind}"]`)) {callback(event, ...event.detail)}
    });
  }
  
  handleAjax('all', (e, json) => FindAndUpdate.all(json));
  handleAjax('classes', (e, json) => FindAndUpdate.classes(json.classes));
  handleAjax('text', (e, json) => FindAndUpdate.text(json.text_updates));
  handleAjax('html', (e, json) => FindAndUpdate.html(json.updates).then(() => FindAndUpdate.glow(json.glows)));
  handleAjax('html-immediate', (e, json) => {
    FindAndUpdate.htmlImmediate(json.updates);
    FindAndUpdate.glow(json.glows);
  });
  handleAjax('disables', (e, json) => FindAndUpdate.disables(json.disables));

  $(document).on('ajax:error', '[data-find-and-update]', function(event) {
    let [json, status, xhr] = event.detail;

    if (json && json['updates']) {
      FindAndUpdate.html(json.updates).then(function() {
        FindAndUpdate.glow(json.glows);
      });
    } else if (!json || !json['field_errors']) {
      alert(json && json['error_message'] ? json['error_message'] : 'Something went wrong');
    }
  });

  $(document).on('find_and_update', '[data-find-and-update-autofocus]', function(event) {
    var selector = $(this).attr('data-find-and-update-autofocus');
    $(selector).focus();
  });
});
