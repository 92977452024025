/*
 * Ensure links to a Bootstrap Tab from outside of the nav tab list work
 * On click the tab is opened and the corresponding nav tab gets the `active` class
 * The script is run only for links outside of the nav tabs list
 *
 */

document.addEventListener('click', (event) => {
  if (event.target.closest('a[data-toggle=tab]') && !event.target.closest('ul.nav.nav-tabs')) {
    let navBarAnchor = event.target.href.split('#');
    let navigationTab = document.querySelector(`.nav a[href="#${CSS.escape(navBarAnchor[1])}"]`);
    let navigation = navigationTab.closest('.nav');

    navigation.querySelector('li.active').classList.remove('active');
    navigationTab.closest('li').classList.add('active');
  }
});
