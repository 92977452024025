window.addEventListener('DOMContentLoaded', function() {
  // if a link in a modal opens another modal, close the current modal
  $(document).on('click', '.modal *[data-toggle="modal"]', function(event) {
    $(event.target).closest('.modal').modal('hide');
  });

  // if a link in a modal opens the eliza modal, close the current modal
  $(document).on('click', '.modal *[data-toggle="eliza"]', function(event) {
    $(event.target).closest('.modal').modal('hide');
  });
});
