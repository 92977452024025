export default class SimpleForm {
  constructor(object, options) {
    this.object = object;
    this._formOptions = options || {};
    this.form = document.createElement('FORM');
  }

  appendTo(root, fn) {
    fn(this);
    root.append(this.form);
    return this;
  }

  input(name, options) {
    let div = document.createElement('DIV');
    div.classList.add('form-group', name, this._formOptions.as ? this._formOptions.as + "_" + name : '');
    div.classList.toggle('hidden', options.hidden === true);
    div.appendChild(this.label(name, options));

    let controls = document.createElement('DIV');
    controls.classList.add('controls');
    controls.appendChild(this.inputElement(name, options));
    if (options.hint) controls.appendChild(options.hint.get(0));

    div.appendChild(controls);
    this.form.appendChild(div);
    return div
  }

  label(name, options) {
    let label = document.createElement('LABEL');
    label.classList.add('control-label');
    label.classList.toggle('required', options.required);
    label.innerHTML = options.label || this.humanizedString(name);
    return label;
  }

  inputElement(name, options) {
    let input = options.input || document.createElement(options.as ? options.as.toUpperCase() : 'INPUT');
    input.classList.add('form-control');
    input.classList.toggle('auto-expand', options.as === 'textarea' && options.autoExpand !== false);
    input.setAttribute('name', options.name || name);
    input.disabled = options.disabled;
    input.readOnly = options.readonly;
    input.value = this.initialValueFor(name, options);
    return input;
  }

  initialValueFor(name, options) {
    return options.value || this.object[name] || this.object[name.replace(/_(.)/g, (a, b) => {return b.toUpperCase()})];
  }

  humanizedString(string) {
    return string.replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, (a, b, c) => {return b + " " + c.toLowerCase()})
      .replace(/^./, (a) => {return a.toUpperCase()});
  }

  values() {
    let formEntries = new FormData(this.form).entries()
    return Object.fromEntries(formEntries);
  }
}
