import {FindAndUpdate} from 'services/find_and_update';

export default class UxVolunteer {
  constructor(options) {
    this._url = options.url;
  }
  
  set(value) {
    return $.ajax({
      url: this._url,
      method: 'patch',
      data: {user: {ux_volunteer: value}}
    }).then(function(json) {
      FindAndUpdate.htmlImmediate(json.updates);
    });
  }
}

window.addEventListener('DOMContentLoaded', function() {
  $(document).on('click', '*[data-ux-volunteer-status-value]', function(event) {
    var $target = $(event.currentTarget);
    var volunteer = new UxVolunteer({url: $target.closest('[data-ux-volunteer-status-url]').attr('data-ux-volunteer-status-url')});
    var value = $target.attr('data-ux-volunteer-status-value');
  
    if (value === 'yes') { $('#ux-volunteer-invitation--thank-you').quintain('flip'); }
    $('#ux-volunteer-invitation').quintain('hide');
    volunteer.set(value)
  });
});
