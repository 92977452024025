import {Picker} from 'pickers/picker';

class AcademicUnitPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: 'Choose a school or department',
  }}
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      dropdownCssClass: 'academic-unit-dropdown',
    };
  }
  
  templateSelection(state, li) {
    if (state.element) {
      $(li).addClass(state.element.dataset.type);
    }
    return state.element?.dataset?.qualifiedName || state.text;
  }
  
  templateResult(state, li) {
    if (state.element) {
      $(li).addClass(state.element.dataset.type);
    }
    return state.text;
  }
}
AcademicUnitPicker.watch('select.academic-unit-picker');
