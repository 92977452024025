import introJs from 'intro.js';

import {FindAndUpdate} from 'services/find_and_update';
import {Components} from 'components/components';

export class TourGuide {
  constructor(name) {
    this._name = name;
    this._analyticsName = name.replace(/[^a-z0-9]+/g, '-');
  }
  
  begin() {
    $.get('/tours/' + this._name).then((json) => {
      this.event('start');
      this._steps = this.normalizeSteps(json.steps);
      this._options = json.options || {};
      this._begin();
    });
  }
  
  _begin() {
    var tourguide = this;
    this._introJs = introJs();

    this._introJs.setOptions(this.options());
    TourGuide.ticket(this._name);
    this._introJs
      .onbeforechange(this.doStepSetup())
      .onchange(this.doAll(this.doStepAnalytics(), this.doStepModalFix()))
      .oncomplete(() => this.event('complete'))
      .onskip(() => this.doExit(this.currentStepIndex))
      .oncomplete((ixStep, skipOrEnd) => this.doExit(skipOrEnd == "skip" ? ixStep : this.totalStepCount))
      .start();
  }
  
  options() {
    return $.extend({
      hintPosition: 'auto',
      overlayOpacity: 0.6,
      exitOnOverlayClick: false,
      steps: this.filteredSteps()
    }, this._options);
  }

  normalizeSteps(steps) {
    return steps.map((step) => {
      step.analytics_id = step.analytics_id || step.original?.element?.replace(/[^a-z]+/g, '-')?.replace(/^-|-$/g, '') || 'unknown';
      return step;
    })
  }
  
  filteredSteps() {
    return this._steps.filter(function(obj) {
      obj.original = {...obj};
      
      if (obj.if) { return eval(obj.if); } // allow stuff to decide if it should show
      if (obj.setup) { return true; } // allow stuff that is not currently showing
      if (!obj.element) { return true; } // allow floating tooltips
      return $(obj.element).filter(':visible').length; // ignore hidden elements
    });
  }
  
  get currentStepIndex() {
    return this._introJs.currentStep();
  }
  
  currentStep() {
    return this.stepAt(this.currentStepIndex);
  }
  
  stepAt(index) {
    return this._introJs._introItems[index];
  }

  get totalStepCount() {
    return this._introJs._introItems.length;
  }
  
  doStepSetup() {
    var teardowns = [];
    return (el) => {
      var step = this.currentStep();
      teardowns.forEach(td => eval(td));
      step?.setup && eval(step.setup);
      step?.skipIf && eval(step.skipIf) === true && this._introJs.nextStep();
      step?.teardown && teardowns.push(step.teardown);
    };
  }
  
  doAll(...callbacks) {
    return (...args) => { callbacks.forEach(callback => callback(...args)) }
  }
  
  doStepAnalytics() {
    return (el) => {
      var step = this.currentStep();
      this.event(`step-${step.analytics_id}`);
    };
  }
  
  doStepModalFix() {
    return function(el) {
      var $modal = $(el).closest('.modal');
      $('.introjs-overlay, .introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-fixedTooltip')
        .appendTo($modal.length ? $modal : document.body);
    };
  }

  doExit(onStepIndex) {
    this.event('exit');
    this.stampTicket(onStepIndex);
  }

  stampTicket(exitIndex) {
    let formData = new FormData();
    let exitStep = this.stepAt(exitIndex);
    
    if (exitStep) {
      formData.set("status", "skipped");
      formData.set("end_step", exitStep.analytics_id);
      formData.set("progress", `${exitIndex+1}/${this.totalStepCount}`);
    } else {
      formData.set("status", "completed");
      formData.set("end_step", "completed-tour");
      formData.set("progress", `${this.totalStepCount}/${this.totalStepCount}`);
    }

    TourGuide.ticket(this._name, {body: formData});
  }
  
  event(action, id=this._analyticsName) {
    analytics.event('tour', action, id);
  }
  
  static ticket(name, fetchOpts={}) {
    return ajaxTracking.fetch(null, `/tours/${name}`, {
      method: 'PUT',
      ...fetchOpts,
      logAs: `tour-ticket-${name}`,
    });
  }
  
  static ripUpTickets() {
    return ajaxTracking.fetch(null, `/tours`, {
      method: 'DELETE',
      logAs: `tour-tickets-reset`,
    })
      .then(response => response.json())
      .then((json) => FindAndUpdate.all(json));
  }
  
  static confirmForgetHistory() {
    Components.Modal.confirm({
      id: 'ok',
      title: 'Forget tour history?',
      body: "Forgetting your tour history will make all tours show again. Do you really want me to forget all the tours you've taken?",
      confirm: 'Yes, forget history',
      whenConfirmed: (modal) => TourGuide.ripUpTickets(),
    });
  }
  
  static beginUntakenTours($in) {
    $in = $in || $(document);
    
    $in.find('[data-guide-name][data-guide-version][data-guide-version-taken]').first().each(function() {
      var name = $(this).attr('data-guide-name');
      var currentVersion = Number($(this).attr('data-guide-version'));
      var takenVersion = Number($(this).attr('data-guide-version-taken'));
      if (takenVersion < currentVersion) {
        $(this).click();
      }
    });
  }
  
  static followGuideposts() {
    let $guidepost = $('[data-guide-updated=true]:visible');
    if ($guidepost.length === 0) { return; }
    
    $guidepost.attr('data-guide-updated', 'false');
    $guidepost.click();
  }
}

window.addEventListener('DOMContentLoaded', function() {
  $(document).on('click', '[data-guide-name]', function(event) {
    if (event.altKey) {
      event.preventDefault();
      TourGuide.confirmForgetHistory();
    } else {
      new TourGuide($(this).data("guide-name")).begin();
    }
  });
  
  $(document).on('click', '[data-guide-forget-all]', function(event) {
    event.preventDefault();
    TourGuide.confirmForgetHistory();
  });
  
  var tourName = new URL(document.location).searchParams.get('tour');
  tourName && new TourGuide(tourName).begin();
  
  TourGuide.beginUntakenTours();
  
  TourGuide.followGuideposts();
  $(document).on('shown.bs.tab shown.bs.modal', () => TourGuide.followGuideposts());
});
