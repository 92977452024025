var noop = function() {};

export default class BusyBody {
  constructor(opts={}) {
    opts = {...this.defaults, ...opts};
    
    this._selector = opts.selector;
    this._startVisits = opts.startVisits;
    this._finishVisits = opts.finishVisits;
    this._added = opts.added;
    this._removed = opts.removed;
    
    BusyBody.register(this, {callNow: opts.callNow});
  }
  
  get selector() { return this._selector; }
  
  get defaults() {
    return {selector: '*', startVisits: noop, finishVisits: noop, added: noop, removed: noop, callNow: true};
  }
  
  visit(mutation) {
    for (var node of this.matchesIn(mutation.addedNodes || [])) {
      this.added(node, this);
    }
    
    for (var node of this.matchesIn(mutation.removedNodes || [])) {
      this.removed(node, this);
    }
  }
  
  matchesIn(nodeList) {
    return Array.prototype.flatMap.call(nodeList, (e) => [
      ...(e.matches && e.matches(this.selector) ? [e] : []),
      ...(e.querySelectorAll ? Array.prototype.slice.call(e.querySelectorAll(this.selector)): []),
    ]);
  }
  
  startVisits() {
    this._startVisits(this);
  }
  
  finishVisits() {
    this._finishVisits(this);
  }
  
  added(node) {
    this._added(node, this);
  }
  
  removed(node) {
    this._removed(node, this);
  }
  
  static register(toRegister, opts={}) {
    opts = {...{callNow: true}, ...opts};
    
    if (!BusyBody.instances) {
      BusyBody.instances = [];
    
      BusyBody.observer = new MutationObserver(mutations => {
        let instances = BusyBody.instances;
        
        instances.forEach(i => i.startVisits());
        for (var mutation of mutations) {
          instances.forEach(i => i.visit(mutation));
        }
        instances.forEach(i => i.finishVisits());
        $(document.body).trigger('visited.busy-body');
      });
      BusyBody.observer.observe(document.body, {childList: true, subtree: true});
    }
    
    BusyBody.instances.push(toRegister);
    
    if (opts.callNow) {
      var callNow = () => {
        toRegister.startVisits();
        toRegister.visit({addedNodes: document.querySelectorAll('body'), removedNodes: []})
        toRegister.finishVisits();
      };
      window.addEventListener('DOMContentLoaded', callNow);
      if (document.readyState !== 'loading') {
        callNow();
      }
    }
  }
}

window.BusyBody = BusyBody;
