import SimpleForm from 'behavior/simple_form';
import {FindAndUpdate} from 'services/find_and_update';
import {Components} from 'components/components';

// ♬ One week later I'm writing a letter nightly

class Eliza {
  constructor(options) {
    this._options = options;
  }

  compose() {
    let eliza = this;
    let options = eliza._options;
    let confirmButton = Components.iconicButton("envelope", eliza._options.confirmButton || 'Send', options, {class: "once btn-primary"});
    confirmButton.addEventListener('click', (event) => {
      event.preventDefault();
      
      $.ajax({
        url: '/analyst/emails.json',
        method: 'POST',
        data: eliza._simpleForm.values()
      }).always(() =>{
        Once.resetWithin(modal);
      }).then((json, status, xhr) => {
        FindAndUpdate.html(json.updates).then(() => {
          FindAndUpdate.text(json.text_updates);
          FindAndUpdate.glow(json.glows);
        });
      }).fail((xhr, errorname, error) => {
        ModalEditor.ajaxError(modal.element, xhr.responseJSON || JSON.parse(xhr.responseText), errorname, xhr);
      });
    });
    
    let modal = new Components.Modal({
      title: 'Send email',
      buttons: [confirmButton],
      ...eliza._options,
      id: 'eliza-compose',
      body: body => {eliza._fillComposeBody(body)},
    }).show();
  }

  _fillComposeBody(body) {
    let eliza = this;

    function hide(field) {
      return eliza._options.hideFields.includes(field);
    }

    body.append(Components.alert('danger', {icon: 'exclamation-triangle', class: 'starts-hidden could-not-save'}));

    if (eliza._options.warning) {
      let alert = Components.alert('warning', {icon: 'hourglass'});
      eliza._fillSlot(alert.querySelector('.text'), 'warning');
      body.append(alert);
    }

    this._fillSlot(body, 'beforeInstructions');
    $('<div>').addClass('instructions').append(eliza._options.instructions).appendTo(body);
    this._fillSlot(body, 'afterInstructions');

    this._fillSlot(body, 'beforeForm');
    this._simpleForm = new SimpleForm(eliza._options, {as: eliza._options.transactionType}).appendTo(body, form => {
      this._fillSlot(form.form, 'topOfForm');

      form.input('transaction_type', {hidden: true});
      form.input('permission_scope_class', {hidden: true});
      form.input('permission_scope_id', {hidden: true});
      form.input('regarding_type', {name: 'mail[regarding_type]', hidden: true});
      form.input('regarding_id', {name: 'mail[regarding_id]', hidden: true});
      form.input('cc', {name: 'mail[cc]', hidden: true});

      form.input('to', {name: 'mail[to]', required: true, hidden: hide('to')});
      form.input('subject', {name: 'mail[subject]', required: true, hidden: hide('subject')});
      form.input('body', {name: 'mail[body]', required: true, as: 'textarea', hidden: hide('body')});

      this._fillSlot(form.form, 'bottomOfForm');
    });

    this._fillSlot(body, 'afterForm');
  }

  _fillSlot(within, slotName) {
    let slot = this._options[slotName];
    if (!slot) return;
      
    $(within).append(
      $('<div>')
      .addClass(slotName.replace(/([a-z])([A-Z])/g, (str, lc, uc) => {return lc + "-" + uc.toLowerCase()}))
      .append(slot.call ? slot(within) : slot)
    );
  }
}

document.addEventListener('click', event => {
  let target = event.target.closest('[data-toggle=eliza]');

  if (target) {
    event.preventDefault();
    let href = target.getAttribute('href');
    let url = href ? new URL(href) : null;
    let query = url ? Object.fromEntries(url.searchParams.entries()) : {};
    let options = {
      to: (url ? decodeURIComponent(url.href.substr(0, url.href.indexOf('?')).replace('mailto:', '')) : null),
      ...query, 
      ...$(target).data(),
    }


    new Eliza(options).compose();
  }
});
