import { Reindexer } from 'services/reindexer';

export class Template {
  constructor(source) {
    this.source = source;
  }
  
  copy({resetForm: resets = {}, resetInteractives: interactives = {}, reindex: reindex = false}) {
    let copy = this.source.cloneNode(true);
    copy.removeAttribute('id');
    copy.classList.remove('template');
    copy.disabled = false;
    
    // we need a wrapper because the copy is often the group that needs to be cleared/reindexed/etc
    let wrapper = document.createElement('div');
    wrapper.appendChild(copy);
    
    if (resets) {
      this.resetFormElements(wrapper, {...resets});
    }
    
    if (interactives) {
      this.resetInteractives(wrapper, {...interactives});
    }
    
    if (reindex) {
      new Reindexer({...reindex, within: wrapper}).reindex(reindex.index);
    }
    
    return copy;
  }
  
  resetFormElements(node, {
    inputs: inputs = '',
    checkboxes: checkboxes = false,
  }) {
    let $node = $(node);
    $node.find(':input').not(':checkbox, :radio')
      .val(inputs);
    $node.find(':checkbox, :radio')
      .prop('checked', checkboxes)
      .closest('label').toggleClass('active', checkboxes);
  }
  
  resetInteractives(node) {
    let $node = $(node);
    
    // reset doomed elements
    $node.find('[data-toggle=doomed]')
      .removeAttr('data-telescope-target')
      .removeAttr('data-doomed-is-setup');
    
    // reset select2 elements
    $node.find('.select2-container')
      .remove();
    $node.find("[data-select2-id]")
      .removeAttr('data-select2-id');
    
    // reset datepicker elements
    let datepickers = $node.find('.hasDatepicker').removeClass('hasDatepicker');
    datepickers.next().remove();
    datepickers.unwrap();
  }
}

window.Template = Template;
