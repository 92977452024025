export default class DuplicateIdFinder {
  constructor(within=document) {
    this.within = within;
  }
  
  get allById() {
    if (this._allById) { return this._allById }
    
    var allById = {};
    
    this.within.querySelectorAll('*[id]').forEach(el => {
      (allById[el.id] = allById[el.id] || []).push(el);
    });
    
    return this._allById = allById;
  }
  
  get duplicatesById() {
    if (this._duplicatesById) { return this._duplicatesById }
    
    var duplicatesById = this.allById;
    
    for (let [id, els] of Object.entries(duplicatesById)) {
      if (els.length === 1) { delete duplicatesById[id]; }
    }
    
    return this._duplicatesById = duplicatesById;
  }
  get duplicatedIds() { return Object.keys(this.duplicatesById); }
  get length() { return this.duplicatedIds.length; }
  
  forEach(fn) {
    for (let [id, els] of Object.entries(this.duplicatesById)) {
      fn(id, els);
    }
  }
}

window.DuplicateIdFinder = DuplicateIdFinder;
