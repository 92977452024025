import { escapeRegExp } from 'lodash';

export default class DynamicSearch {
  constructor(elements, options={}) {
    this.elements = elements || document.querySelectorAll('#match-nothing');
    this.options = options || {};
  }
  
  search(query) {
    let results = {matched: [], missed: []};
    
    this.elements.forEach((el) => {
      let matched = DynamicSearch.isMatch(el, query);
      this.searchActions.forEach(fn => fn?.call(this, el, matched, query));
      (matched ? results.matched : results.missed).push(el);
    })
    
    return results;
  }
  
  get searchActions() {
    return this.options.actions || this.defaultSearchActions;
  }
  
  get defaultSearchActions() {
    return [
      DynamicSearch.updateMisses(this.options.missClass),
      (this.options.matchClass ? DynamicSearch.updateMatches(this.options.matchClass) : null),
      DynamicSearch.markMatches(),
    ];
  }
  
  static isMatch(el, query) {
    return (el.dataset?.search || '').includes(query);
  }
  
  static updateMatches(matchClass) {
    return (el, matched) => el.classList.toggle(matchClass || 'show', matched);
  }
  
  static updateMisses(missClass) {
    return (el, matched) => el.classList.toggle(missClass || 'hidden', !matched);
  }
  
  static markMatches() {
    return (el, matched, query) => {
      query = escapeRegExp(query);
      
      el.querySelectorAll('mark.dynamic-search-term').forEach(mark => {
        let parent = mark.parentElement;
        mark.replaceWith(...mark.childNodes);
        parent?.normalize();
      });
      
      if (!matched) { return }
      if (!query) { return }
      
      let pattern = new RegExp(`(?<![a-z0-9])(${query})`, 'ig');
      let replaceIn = function(sub) {
        sub.childNodes.forEach(child => {
          if (Object.getPrototypeOf(child) === Text.prototype) {
            child.replaceWith(...child.textContent.split(pattern).map(
              s => s.match(pattern) ? Components.tag('mark', {class: 'dynamic-search-term'}, s) : s
            ));
          } else {
            replaceIn(child);
          }
        });
      };
      
      replaceIn(el);
    };
  }
}

window.DynamicSearch = DynamicSearch;
