import {RemotePicker} from 'pickers/picker';

class TagPicker extends RemotePicker {
  get url() { return '/api/pickers/tags.json' }
  get resultsKey() { return 'tags' }
  get strings() {
    return {
    ...super.strings,
    placeholder: this.el.getAttribute('placeholder') || 'Choose a tag',
  }}

  get pickerOptions() {
    return {
      ...super.pickerOptions,
      minimumInputLength: 0,
      maximumInputLength: 127,
    };
  }

  processResult(tag) {
    return {text: tag, id: tag};
  }
}

TagPicker.watch('select.tag-picker');
