export class Sherlock {
  static alias(el) {
    return `${el.tagName.toLowerCase()}${el.id ? `#${el.id}` : (el.classList.length > 0 ? `.${el.classList[0]}` : '')}`;
  }
  
  static fingerprint(el) {
    let clone = el.cloneNode(true);
    clone.innerText = '…';
    return clone.outerHTML;
  }
  
  static aliasElement(el) {
    return Components.tag('span', {class: "sherlock-element", title: el.className}, Sherlock.alias(el));
  }
  
  static steps(el) {
    let steps = [el];
    for (let current = el; current = current.parentElement; current) {
      steps.unshift(current);
    }
    return steps;
  }
  
  static path(el) {
    return Components.tag('span', {class: "sherlock-path"}, Sherlock.steps(el).map(e => Sherlock.aliasElement(e)));
  }
  
  static pathTo(el) {
    return Sherlock.path(el.parentNode);
  }
  
  static stringPath(el) {
    return Sherlock.steps(el).map(e => Sherlock.alias(e)).join(" > ");
  }
  
  static stringPathTo(el) {
    return Sherlock.stringPath(el.parentNode);
  }
}

window.Sherlock = Sherlock;