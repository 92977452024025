const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.click();
      observer.unobserve(entry.target);
    }
  });
}, {threshold: 0.5});

window.addEventListener('DOMContentLoaded', (event) => {
  new BusyBody({
    selector: 'a.js-lazy-load-trigger',
    added: e => observer.observe(e),
  });
});
