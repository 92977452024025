import {Telescope} from 'behavior/telescope';

export class Pluralizable {
  constructor(el) {
    this.el = el;
  }
  
  get countEls() { return this.el.querySelectorAll('.pluralizable--count') }
  set count(v) {
    this.countEls.forEach(e => e.innerText = v);
    this.#update(v === 1 ? 'single' : 'plural');
  }
  
  #update(value) {
    new Telescope(`#${this.el.id}`).val(value);
  }
  
  watch() {
    new MutationObserver(mutations => {
      this.count = Number(this.el.dataset.count);
    }).observe(this.el, {attributes: true, attributeFilter: ['data-count']});
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new BusyBody({
    selector: '.pluralizable',
    added: (el) => new Pluralizable(el).watch(),
  });
});
