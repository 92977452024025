import { RemotePicker } from 'pickers/picker';

class UserPicker extends RemotePicker {
  get url() { return '/api/pickers/users.json'; }
  get resultsKey() { return 'users' }
  get overridesAttributeName() { return 'userPickerOpts'; }
  
  get strings() {
    return {
      ...super.strings,
      placeholder: 'Search by name, email address, etc',
    };
  }
  
  get pickerOptions() {
    let opts = super.pickerOptions;
    
    if (this.el.matches('.inline-picker')) { opts.width = 'auto'; }
    if (this.permission) { opts.minimumInputLength = 0; }
    
    return opts;
  }
  
  get permission() {
    return this.el.dataset.permission;
  }
  
  ajaxData(params) {
    let always = {q: params.term, page: params.page};
    let dataParams = JSON.parse(this.el.dataset.pickerParams || '{}');
    let permissionData = this.permission ? {permission: this.permission, permission_on: this.el.dataset.permissionOn} : {};
    
    return {q: params.term, page: params.page, ...dataParams, ...permissionData};
  }
  
  processResults(data) {
    let dataset = super.processResults(data);
    return {...dataset, results: this.excludeUsers(dataset.results, this.el.dataset.usersToExclude)};
  }
  
  processResult(user) {
    return {text: user.alias, id: user.external_user_id, user: user};
  }
  
  templateResult(state) {
    if (!state.id) return state.text; // optgroup
    return $('<div class=user />').
      append($('<span class=name />').text(state.user.name)).
      append($('<span class=email />').text(state.user.email_address));
  }
  
  templateSelection(state) {
    if (!state.id) return state.text; // optgroup
    return state.user ? state.user.name : state.text;
  }
  
  excludeUsers(userList, usersToExcludeData) {
    if (typeof usersToExcludeData !== 'undefined') {
      let usersToExclude = String(usersToExcludeData).split(' ');
      userList = $.grep(userList, function(user) {
        return $.inArray(user.id, usersToExclude) < 0;
      });
    }
    return userList;
  }
}
UserPicker.watch('.user-picker');
