export default class Appointments {
  constructor(holder = document) {
    this.holder = holder;
  }

  static closest(el) {
    return new Appointments(el.closest('.js-appointments'));
  }

  add() {
    let template = this._template();
    let newApptFields = template.copy({reindex: {
      index: this.appointments().length,
      groupName: 'appointments',
      groupBy: '.appointment',
      groupCallback: (el, ix) => el.querySelector('legend .number').textContent = ix + 1
    }});

    this.enable(newApptFields);
    this.holder.querySelector('.appointments').append(newApptFields);
    this.setEditable(true);

    return this;
  }

  enable(appt) {
    return this.disable(appt, false);
  }

  disable(appt, disable = true) {
    appt.disabled = disable
    return this;
  }

  setEditable(editable) {
    this.holder.querySelector('.when-editable')?.classList?.toggle('hidden', !editable);
    this.holder.querySelector('.when-not-editable')?.classList?.toggle('hidden', editable);

    editable = editable && !this.locked();
    editable ? this.enableAll() : this.disableAll();

    return this;
  }

  enableAll() {
    this.appointments().forEach((appointment) => {
      this.enable(appointment);
    });
  }

  disableAll() {
    this.appointments().forEach((appointment) => {
      this.disable(appointment);
    });
  }

  ensureAtLeastOneAppointment() {
    let sparedAppointments = this.appointments(':not(.doomed)');
    if (sparedAppointments.length === 0) this.add();
    sparedAppointments.forEach((appointment) => {
      this.enable(appointment);
    });
    return this;
  }

  appointments(caveat) {
    return this.holder.querySelectorAll(`.appointment${caveat ?? ''}`);
  }

  locked() {
    return this.holder.closest('form').dataset.locked === 'true';
  }

  _template(){
    let templateEl = this.appointments()[0];
    return new Template(templateEl);
  }
}
