class InfiniteScrolling {
  constructor({watch, beforeload, afterload}) {
    this.selector = watch;
    this.beforeload = beforeload || (() => {});
    this.afterload = afterload || (() => {});
  }
  
  get autoloader() {
    return this._autoloader = this._autoloader || new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.triggerAutoload(entry.target);
          observer.unobserve(entry.target);
        }
      });
    }, {threshold: 0.5});
  }
  
  triggerAutoload(target) {
    this.beforeload(target);
    target.click();
    this.afterload(target);
  }
  
  autoload() {
    if (!window.IntersectionObserver) { return; }
    
    new BusyBody({
      selector: this.selector,
      added: (el) => this.autoloader.observe(el),
    });
  }
}

window.addEventListener('DOMContentLoaded', function() {
  new InfiniteScrolling({
    watch: "*[data-table-tyrant-autoload]",
    beforeload: (target) => {
      let selectAll = document.querySelector('*[name="select-all"]');
      if (selectAll && selectAll.checked) {
        target.dataset.params = JSON.stringify({"select-all": true});
      }
    },
    afterload: (target) => {
      let $grid = $(target.closest('.table-tyrant'));
      $grid.one('find_and_update', (event) => $grid.trigger('restripe.recruit.table-tyrant'));
    },
  }).autoload();
});
