import Appointments from "./appointments";

export default class OfferEditor {
  constructor(el) {
    this.el = el;
  }
  
  get form() { return this.el.closest('form') }
  get statusCheckbox() { return this.form.querySelector('.update-status-form--pick-a-status :checked') }
  get offerIs() { return this.statusCheckbox?.dataset?.offerIs }
  set offerIs(v) {
    if (v === "forbidden") {
      this.appointments?.setEditable(false);
    } else if (v === "optional") {
      var keepIfVisible = !$(".when-editable").is(".hidden");
      this.appointments?.setEditable(keepIfVisible);
    } else if (v === "required") {
      this.appointments?.setEditable(true);
    }
    
    this.updateSubmitButton();
  }
  
  get appointments() { return this.el.matches('.js-appointments') ? new Appointments(this.el) : null }
  get locked() { return this.appointments?.locked() }
  
  updateOfferIs() {
    this.offerIs = this.offerIs;
  }
  
  updateSubmitButton() {
    let appointments = this.appointments.appointments(':not(.doomed)');
    let invalidOffer = this.offerIs === 'required' && appointments.length === 0;
  
    this.form.querySelector('.modal-footer button').disabled = this.locked || invalidOffer;
  }
}

window.addEventListener('DOMContentLoaded', () => {
  $(document).on('did-change.telescope', '.update-status-form, .offer-editor .appointment', function(event, telescope, scopes) {
    let el = this.closest('.offer-editor') || this.querySelector('.offer-editor');
    if (el) { new OfferEditor(el).updateOfferIs() }
  });
});
