import Rails from '@rails/ujs';
import BusyBody from 'services/busy_body';

export class Picker {
  constructor(el) {
    this.el = el;
    this.setup();
    $(this.el).data('picker', this);
  }

  get strings() {
    return {
      placeholder: 'Please make a selection',
    };
  }
  
  get pickerOptions() {
    return {
      width: '100%',
      placeholder: this.strings.placeholder,
      templateSelection: this.templateSelection.bind(this),
      templateResult: this.deduplicateOptionGroups.bind(this),
      ...this.pickerOverrides,
    };
  }
  
  get pickerOverrides() {
    return JSON.parse(this.el.dataset[this.overridesAttributeName || 'pickerOpts'] || '{}');
  }
  
  templateSelection(obj) {
    return (obj.hp ? this.templateResult(obj) : obj.text) || this.strings.placeholder;
  }
  
  deduplicateOptionGroups(obj, el) {
    let select2 = $(this.el).data('select2');
    
    // this is an optgroup
    if (select2 && obj.children && obj.children.length > 0) {
      let optGroupText = this.optGroupLabel(obj);
      let select2results = select2.$results;
      let existingOptGroup = select2results
        .find(".select2-results__group")
        .first(e => e.text === optGroupText);
      if (existingOptGroup.length) { return null }
    }

    // normal option
    return this.templateResult(obj, el);
  }
  
  templateResult(obj) {
    return obj.hp ? `${obj.text} (${obj.id})` : obj.text;
  }
  
  templateOptGroup(obj) {
    var element = obj.element;
    return element ? element.parentElement.getAttribute('label') : null;
  }
  
  optGroupLabel(obj) {
    return obj.text;
  }
  
  setup() {
    var $el = $(this.el);
    if ($el.is('.select2-container')) return;
    if ($el.data('select2')) return;
    
    let opts = {...this.pickerOptions, disabled: this.el.disabled};
    $el.data('original-picker-opts', opts);
    $el.select2(opts);
    
    this.#watchDisabled($el[0]);

    const announceForScreenReader = (message) => {
      const liveRegion = document.getElementById('sr-live-region');
      liveRegion.textContent = '';
      setTimeout(() => {
        liveRegion.textContent = message;
      }, 100); // ensure the content change is detected
    };

    $(document).on('mouseenter', '.select2-results__option', function () {
      let text = $(this).data('sr-text');
      announceForScreenReader(text);
    });

    if (this.el.matches('.autosubmit')) {
      $el.on('change', (event) => {
        if (Rails.fire(this.el, "autosubmit.picker")) {
          this.el.form?.submit(); // no special handling; just submit
        }
      });
    }
  }
  
  #closestDisabledAncestor(el) {
    return el.closest('fieldset[disabled], .form-group.disabled, [disabled]');
  }
  
  #watchDisabled(el) {
    let select = el;
    let updateDisabledState = () => {
      let isSetDisabled = String(select.disabled) === select.dataset.pickerDisabled ? false : select.disabled;
      let disable = isSetDisabled || this.#closestDisabledAncestor(select.parentElement) !== null;
      if (disable !== select.disabled) {
        select.dataset.pickerDisabled = disable;
        select.disabled = disable;
        Telescope.useCurrentValues(select);
      }
    };
    updateDisabledState();
    
    let observer = new MutationObserver(updateDisabledState);
    let watched = select;
    while (watched) {
      observer.observe(watched, {attributes: true, attributeFilter: ['disabled', 'class'], attributeOldValue: true});
      watched = watched.parentElement;
    }
  }
  
  static watch(selector, ...overrides) {
    let pickerClass = this;
    let setWatch = () => {
      return new BusyBody({
        selector: selector,
        added: (el) => new pickerClass(el),
        ...overrides,
      });
    };
    
    if (/complete|interactive|loaded/.test(document.readyState)) {
      setWatch();
    } else {
      window.addEventListener('DOMContentLoaded', setWatch);
    }
  }
}

export class RemotePicker extends Picker {
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      minimumInputLength: 2,
      ajax: this.ajaxOptions,
    };
  }
  
  get ajaxOptions() {
    return {
      url: this.url,
      dataType: 'json',
      delay: 100,
      data: this.ajaxData.bind(this),
      processResults: this.processResults.bind(this),
    };
  }
  
  ajaxData(params) {
    var dataParams = JSON.parse(this.el.dataset.pickerParams || '{}');
    return {...dataParams, q: params.term, page: params.page};
  }
  
  processResults(data) {
    let results = $.map(data[this.resultsKey || 'data'] || [], this.processResult);
    let staticResults = JSON.parse(this.el.dataset.staticResults || '[]');
    return {...data, results: [...results, ...staticResults]};
  }
  
  processResult(obj) {
    return obj;
  }
}

