window.addEventListener('DOMContentLoaded', function() {
  // linked elements will search within their children for a link to follow, effectively making the entire element the link
  $(document).on('click', '.linked', function(event) {
    if ($(event.target).closest('a, .linked-target, input').length > 0) return;
    if ($(event.target).closest('[data-linked-blocker], .linked-blocker').length > 0) return;
    if ($(event.target).closest('.modal').closest(this).length > 0) return;
    
    var link = $(this).find('.linked-target');
    link = link.length ? link : $(this).find('a[href]');
    var href = link.attr('href');
    
    if (href === '#' || link.is('.linked-local, .js')) {
      link.click();
    } else if (href) {
      window.location = href;
    }
  });
});
