export class Artemis {
  constructor(root) {
    this.root = root;
    this.addListeners();
  }
  
  get searchbox() { return this.root.querySelector('.artemis--searchbox'); }
  
  get huntSelector() {
    return `${this.root.dataset.artemisHuntScope} ${this.root.dataset.artemisHuntElements || "*[data-search]"}`;
  }
  
  get searcher() {
    return new DynamicSearch(document.querySelectorAll(this.huntSelector));
  }
  
  get allFilteredOutElement() {
    return this.root.querySelector('.artemis--all-filtered-out');
  }
  
  act(event) {
    document.querySelector(`${this.root.dataset.artemisHuntScope} .action-target`).click();
  }
  
  hunt(event) {
    let q = event.target.value.toLowerCase();
    let {matched, missed} = this.searcher.search(q);
    
    this.afterSearch(q, matched, missed, this.searcher.elements);
  }
  
  afterSearch(q, matched, missed, searched) {
    this.setActionTarget(q.length > 0, searched);
    this.toggleAllFilteredOutElement(q, matched, missed, searched);
  }
  
  setActionTarget(hasQuery, searched) {
    let potentials = [...searched].filter(el => el.matches('.artemis-potential-action-target'));
    potentials.forEach(el => el.classList.remove('action-target'));
    if (hasQuery) { potentials.filter(el => !el.matches('.hidden'))[0]?.classList?.add('action-target') }
  }
  
  toggleAllFilteredOutElement(q, matched, missed, searched) {
    this.allFilteredOutElement.classList.toggle('hidden', q.length === 0 || (missed.length < searched.length));
  }
  
  _analytics(event) {
    analytics.event('artemis', event, this.root.attr('data-analytics-id') || modal.attr('id'));
  }
  
  addListeners() {
    this.searchbox?.addEventListener('keyup', event => {
      switch (event.which) {
      case 13: // enter
        this.act(event);
        break;
      default:
        this.hunt(event);
      }
    })
  }
}

window.addEventListener('DOMContentLoaded', function() {
  new BusyBody({
    selector: '.artemis',
    added: e => new Artemis(e),
  })
});
