import Rails from '@rails/ujs';
import BusyBody from 'services/busy_body';

class SelectedCollector {
  constructor({form, as, all, counter, collect, collectPages}) {
    this.form = form;
    Rails.setData(form, 'selectedCollectorObj', this);
    
    this.as = as || 'selected[]';
    this.all = all || "input[name='select-all']";
    this.collect = collect || 'input.selected';
    this.collectPages = collectPages || '*[data-page][data-keys]';
    this.counter = counter || '.selection-collector-count';
  }
  
  get selectAll() { let cb = this.selectAllCheckbox; return cb && cb.checked; }
  get selectAllCheckbox() { return document.querySelector(this.all); }
  get analyticsId() { return this.form.dataset.analyticsId || this.form.id; }
  
  get checkboxes() { return Array.from(document.querySelectorAll(this.collect)) }
  get checkedCheckboxes() { return this.checkboxes.filter(cb => cb.checked) }
  get uncheckedCheckboxes() { return this.checkboxes.filter(cb => !cb.checked) }
  
  pages() {
    return $(this.collectPages);
  }
  
  get values() {
    let cbValues = this.checkedCheckboxes.map(cb => cb.value);
    let hiddenPageValues = this.selectAll ? this.pages().toArray().flatMap(p => p.dataset.keys.split(',')) : [];
    return [...cbValues, ...hiddenPageValues];
  }
  
  setup() {
    this._addFormListeners();
    this._addCheckboxListeners();
    
    this._updateSelectAll();
    this._updateFormSubmission();
    
    return this;
  }
  
  _addFormListeners() {
    this.form.addEventListener('submit', (event) => {
      let vals = this.values;
      this._copyValuesToForm(vals);
      
      analytics.event('selected-collector',
        this.analyticsId,
        String(vals.length));
    });
  }
  
  _copyValuesToForm(vals = this.values) {
    let holder = this.form.querySelector('.selected-collector-collection');
    if (holder) {
      holder.innerHTML = '';
    } else {
      holder = $("<div class='selected-collector-collection hidden'></div>").appendTo(this.form)[0];
    }
    
    vals.forEach((value) => $('<input type="hidden"/>').attr('name', this.as).val(value).appendTo(holder));
  }
  
  _addCheckboxListeners() {
    $(document).on('change', this.collect, (event) => {
      this._updateSelectAll();
      this._updateSelectedCount();
      this._updateFormSubmission();
      
      $(event.target).trigger('individual-selection-changed');
      $(event.target).trigger('selection-changed');
    });

    $(document).on('change', this.all, (event) => {
      var on = event.target.checked;
      this.checkboxes.forEach(cb => {
        if (cb.checkboxes !== on) {
          cb.checked = on;
          $(cb).trigger('individual-selection-changed');
        }
      });
      
      this._updateSelectedCount();
      this._updateFormSubmission();
      
      $(event.target).trigger('selection-changed');
    });
  }
  
  _updateSelectAll() {
    let visibleOn = this.checkedCheckboxes.length;
    let allOn = this.uncheckedCheckboxes.length === 0;
    let allOff = visibleOn === 0;
    let hasPages = this.pages().length > 0;
    
    $(this.all).prop('checked', allOn && !hasPages);
    $(this.all).prop('indeterminate', hasPages ? (visibleOn > 0) : (!allOn && !allOff));
  }
  
  _updateSelectedCount() {
    let count = this.values.length;
    var counted = count === 1 ? $(this.counter).attr('data-singular') : $(this.counter).attr('data-plural');
    counted = counted ? " " + counted : "";
    $(this.counter).text(count + counted);
  }
    
  _updateFormSubmission() {
    let count = this.values.length;
    let empty = count === 0;
    
    this.form.querySelectorAll('*[data-selected-collector-disable="empty"]').forEach(el => {
      el.classList.toggle('disabled', count === 0);
      el.disabled = count === 0;
    });
  }
  
  static create(form) {
    let obj = Rails.getData(form, 'selectedCollectorObj');
    if (obj) { return obj; }
      
    return new SelectedCollector({
      form: form,
      as: form.dataset.selectedCollectorAs,
      all: form.dataset.selectedCollectorAll,
      collect: form.dataset.selectedCollectorCollect,
      collectPages: form.dataset.selectedCollectorCollectPages,
      counter: form.dataset.selectedCollectorCounter,
    }).setup();
  }
  
  static get formQuery() { return [
    'form[data-selected-collector]',
    'form[data-selected-collector-as]',
    'form[data-selected-collector-all]',
    'form[data-selected-collector-collect]',
    'form[data-selected-collector-collect-pages]',
    'form[data-selected-collector-counter]',
  ].join(', ') };
}

window.addEventListener('DOMContentLoaded', function() {
  $.extend($.expr.pseudos, {unchecked: function( a ) { return !$(a).prop("checked"); }});
  
  new BusyBody({
    selector: SelectedCollector.formQuery,
    added: (el) => SelectedCollector.create(el),
  });
  
  $.fn.selectedCollector = function() {
    return Rails.getData(this[0], 'selectedCollectorObj');
  };
});
