window.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', (event) => {
    let link = event.target.closest('a.back, button.back');
    
    if (link) {
      event.preventDefault();
    
      let referrer = document.referrer;
      if (referrer && new URL(referrer).host !== document.location.host) referrer = null;
    
      document.location =
        link.dataset.backFallbackUrl ||
        referrer ||
        link.href ||
        document.location.pathname.match(/\/.*?\//) ||
        '/';
    }
  });
});
