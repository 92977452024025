import {Picker} from 'pickers/picker';

class DepartmentPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: this.el.getAttribute('placeholder') || 'Choose a department',
  }}
  
  templateSelection(obj) {
    let school = this.templateOptGroup(obj);
    return ((school) ? `${obj.text} / ${school}` : obj.text) || this.strings.placeholder;
  }
}
DepartmentPicker.watch('.department-picker');
