import {Picker} from 'pickers/picker';

class ApplicationPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: this.el.getAttribute('placeholder') || 'Type to start searching for an applicant...',
  }}
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      dropdownAutoWidth: true,
      allowClear: true
    };
  }
}
ApplicationPicker.watch('.application-picker');
