import {Components} from 'components/components';
import mailcheck from 'mailcheck';

export class MailChecker {
  constructor(input) {
    this._input = input;
  }
  
  validate() {
    var mailChecker = this;
    MailChecker.mailcheck.run({
      email: $(mailChecker._input).val(),
      suggested: function(suggestion) {
        mailChecker._removeWarning();
        mailChecker._addSuggestion(suggestion['full']);
      },
      empty: function(element) {
        mailChecker._removeWarning();
      }
    });
  }
  
  _addSuggestion(suggestion) {
    var suggestionLink = $("<a href='#' class='suggestion-link' />").text(suggestion);
    var markup = $("<div class='mailcheck-suggestion text-warning'></div>")
      .append(Components.iconic('exclamation-triangle'))
      .append(" Do you mean ").append(suggestionLink).append("?");

    this._input.after(markup);
    this._addSuggestionLinkListener(suggestionLink);
  }
  
  _removeWarning() {
    this._input.parent().find('div.mailcheck-suggestion').remove();
  }

  _addSuggestionLinkListener(link) {
    var mailChecker = this;
    
    link.click(function (e) {
      e.preventDefault();
      mailChecker._input.val($(this).text());
      mailChecker._removeWarning();
    });
  }
  
  static setup(within) {
    $(within || document).on('blur', 'input[type=email]', function() {
      if($(this).data('email-suggestion') === false) return;
      new MailChecker($(this)).validate();
    });
  }
}
MailChecker.mailcheck = mailcheck;
mailcheck.defaultTopLevelDomains.push('ac.uk')

window.addEventListener('DOMContentLoaded', function() {
  MailChecker.setup();
});
