import Plotly from "plotly.js-dist";

class ChartMaker {
  constructor(el) {
    this.el = el;
    this.opts = {
      ...el.dataset,
      chartTraces: JSON.parse(el.dataset.chartTraces || "[]"),
      chartLayout: JSON.parse(el.dataset.chartLayout || "{}"),
      chartConfig: JSON.parse(el.dataset.chartConfig || "{}"),
    };
  }

  create() {
    Plotly.newPlot(
      this.el,
      this.opts.chartTraces,
      { // https://plotly.com/javascript/reference/layout/
        colorway: "DA5526 697F90 FEBC38 F6893D D8C6B4".split(" "), // color-blind friendly; see https://venngage.com/blog/color-blind-friendly-palette/
        dragmode: "select",
        legend: {orientation: "h"},
        margin: {t: 0},
        modebar: {
          keep: "resetViews toImage".split(" "),
          remove: "zoom2d pan2d select2d lasso2d zoomIn2d zoomOut2d autoScale2d resetScale2d zoom3d pan3d orbitRotation tableRotation handleDrag3d resetCameraDefault3d resetCameraLastSave3d hoverClosest3d hoverClosestCartesian hoverCompareCartesian zoomInGeo zoomOutGeo resetGeo hoverClosestGeo hoverClosestGl2d hoverClosestPie toggleHover sendDataToCloud toggleSpikelines resetViewMapbox".split(" "),
        },
        transition: {
          duration: 2,
        },
        ...this.opts.chartLayout,
      },
      {
        displaylogo: false,
        responsive: true,
        toImageButtonOptions: {filename: this.opts.chartName || 'chart'},
        ...this.opts.chartConfig,
      },
    );

    const adjustLegendAnnotationPosition = () => {
      const annotationsArray = this.el.layout.annotations;
      const annotation = annotationsArray ? annotationsArray[0] : "";
      const legend = this.el.layout.legend;
      if (!annotation || !legend) return;
      
      const margin = 0.05;
      const lines = annotation.text.split('<br>');
      const annotationHeight = (lines.length * (annotation.font.size || 12))/this.el.layout.height;
      const adjustedYPosition = (legend.y || -0.1) - annotationHeight - margin;

      Plotly.relayout(this.el, {
        'annotations[0].y': adjustedYPosition,
        'legend.y': adjustedYPosition,
      });

      this.el.removeListener('plotly_afterplot', adjustLegendAnnotationPosition);
    };

    this.el.on('plotly_afterplot', adjustLegendAnnotationPosition);
  }
}

window.addEventListener('DOMContentLoaded', function() {
  new BusyBody({
    selector: '[data-chart-traces]',
    added: (e) => new ChartMaker(e).create(),
  });
});
