export class Quintain {
  constructor(element, options) {
    this.$element = $(element);
  }

  event(eventName, id) {
    analytics.event(
      'quintain',
      eventName,
      this.$element.attr('data-analytics-id') || this.$element.attr('id')
    );
    return this;
  }

  show() {
    var quintain = this;

    if (quintain.$element.is('.out, .hidden')) {
      return setTimeout(function() {
        quintain.$element.removeClass('out hidden');
        quintain.show();
      }, $('html').is('.test') ? 1 : 100);
    }

    $('.quintain--post').css({top: window.scrollY});

    quintain.event('show');
    quintain.$element
      .one('transitionend', function() {
        quintain.event('shown');
        quintain.$element
          .attr('aria-hidden', false)
          .trigger('shown.recruit.quintain');
        quintain.$element
          .find('.quintain--actions :focusable')
          .first()
          .focus();
      })
      .addClass('in');
  }

  hide() {
    var quintain = this;
    quintain.event('hide');
    quintain.$element
      .one('transitionend', function(event) {
        if (event.originalEvent.propertyName === 'transform') {
          quintain.event('hidden');
          quintain.$element
            .removeClass('in out')
            .addClass('hidden')
            .trigger('hidden.recruit.quintain');
        }
      })
      .attr('aria-hidden', true)
      .addClass('out');
  }

  flip() {
    var quintain = this;
    quintain.event('flip');
    quintain.$element.one('shown.recruit.quintain', function() {
      setTimeout(function() {quintain.hide();}, $.fx.off ? 1 : 2000);
    })
    quintain.show();
  }
}

window.addEventListener('DOMContentLoaded', function() {
  $.fn.quintain = function(option) {
    return this.each(function () {
      var $this   = $(this);
      var data    = $this.data('recruit.quintain');
      var options = $.extend({}, Quintain.DEFAULTS, $this.data(), typeof option === 'object' && option);

      if (!data) $this.data('recruit.quintain', (data = new Quintain(this, options)));
      if (typeof option === 'string') data[option].call(data);
    })
  };

  $(document).on('click.recruit.quintain.data-api', '[data-toggle="quintain"]', function(event) {
    event.preventDefault();
    var selector = $(event.currentTarget).attr('data-target');
    $(selector).quintain('show');
  });

  $(document).on('click.recruit.quintain.data-api', '[data-dismiss="quintain"]', function(event) {
    var selector = $(event.currentTarget).attr('data-target');
    var $target = selector ? $(selector) : $(event.currentTarget).closest('.quintain');
    $target.quintain('hide');
  });

  setTimeout(function() {
    $('.quintain[data-should-show="true"]').quintain('show');
  }, 300);
});

