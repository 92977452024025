import {RemotePicker} from 'pickers/picker';

class WaiverPicker extends RemotePicker {
  get url() { return '/api/pickers/waivers.json' }
  get resultsKey() { return 'waivers' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Search by SWR# or name',
  }}
  
  processResult(hp) {
    return {text: hp.position_working_title, id: hp.code, hp: hp};
  }
}
WaiverPicker.watch('.waiver-picker');
