import { FindAndUpdate } from '../../services/find_and_update';

export default class VeilModal {
  static autoOpen() {
    let applicationId = document.location.hash.match(/^#update-veil-for-(\d+)$/);
    if (applicationId) setTimeout(() => VeilModal.openLink(applicationId[1]), 1);
  }
  
  static openLink(applicationId) {
    let links = document.querySelectorAll(`.to-update-veil[data-application-id="${applicationId}"]`);
    if (links.length) {
      links[0].dataset.includeUpdates = JSON.stringify(
        Array.from(links).reduce((arr, link) => [...arr, ...JSON.parse(link.dataset.includeUpdates || '[]')], [])
      );
      links[0].click();
    }
  }
  
  static open(applicationId, {includeUpdates}) {
    VeilModal.remove();
    
    fetch(`/analyst/applications/${applicationId}/veil.json`)
      .then(response => response.json())
      .then(json => {
        let modal = Components.htmlTree(json.modal).children[0];
        if (includeUpdates) {
          modal.querySelector('.update-veil--include-updates').value = includeUpdates;
        }
        document.body.append(modal);
        
        $(`#update-veil-for-${applicationId}`)
          .modal('show')
          .on('hidden.bs.modal', () => VeilModal.remove());
      })
      .catch(e => console.error(`Could not open veil modal: ${e}`, e));
  }
  
  static remove() {
    document.querySelector('.update-veil-form')?.remove();
  }
}

window.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', event => {
    let trigger = event.target;

    if (trigger.matches('.to-update-veil')) {
      VeilModal.open(trigger.dataset.applicationId, {includeUpdates: trigger.dataset.includeUpdates});
    }
  });
  VeilModal.autoOpen();

  $(document).on("ajax:error", '.update-veil-form', function(event) {
    ModalEditor.ajaxError($(this), ...event.detail);
  });
});
