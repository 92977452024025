class SkipNavigation {
  constructor(skipNavSelector = '.skip-nav', mainContentSelector = '#content') {
    this.skipNav = document.querySelector(skipNavSelector);
    this.mainContentSelector = document.querySelector(mainContentSelector);
    this.init();
  }

  init() {
    if (this.skipNav) {
      ['click', 'keydown'].forEach(eventType =>
        this.skipNav.addEventListener(eventType, (event) => {
          if (eventType === 'click' || (eventType === 'keydown' && event.key === 'Enter')) {
            this.handleSkipNav(event);
          }
        })
      );
      // check for JS else fallback to href
      this.skipNav.removeAttribute('href')
    }
  }

  getFocusableElements(container) {
    const focusableSelectors = ':is(a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])):not([disabled]):not([aria-hidden]):not(.modal *):not(.modal):not([type="hidden"])';
    return [...container.querySelectorAll(focusableSelectors)];
  }

  scrollIntoViewWithFocus(element) {
    element.focus();
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  handleSkipNav(event) {
    event.preventDefault();

    const mainContent = this.mainContentSelector;

    if (mainContent) {
      const [firstFocusableElement] = this.getFocusableElements(mainContent);

      if (firstFocusableElement) {
        this.scrollIntoViewWithFocus(firstFocusableElement);
      } else {
        mainContent.setAttribute('tabindex', '-1');
        this.scrollIntoViewWithFocus(mainContent);
        mainContent.addEventListener('blur', () => {
          mainContent.removeAttribute('tabindex');
        }, { once: true });
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new SkipNavigation();
});
