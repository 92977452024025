import {Components} from 'components/components';

document.addEventListener('DOMContentLoaded', () => {
  function autoResizeListener(event) {
    if (event.target instanceof Element && event.target.matches('.auto-expand')) {
      autoResize(event.target);
    }
  }

  function autoResize(textarea) {
    textarea.style.height = 'auto';

    let newHeight = textarea.scrollHeight;
    let style = getComputedStyle(textarea);
    if (style.boxSizing === 'border-box') {
      newHeight += Number(style.borderTopWidth.replace('px', ''));
      newHeight += Number(style.borderBottomWidth.replace('px', ''));
    }

    textarea.style.height = Math.max(34, newHeight) + 'px';
  }

  document.querySelectorAll('.auto-expand').forEach(el => autoResize(el));

  document.addEventListener('focus', autoResizeListener);
  document.addEventListener('input', autoResizeListener);
  document.addEventListener('blur', autoResizeListener);

  $(document).on('shown.bs.modal', function(event) {
    $(event.target).find('.auto-expand').each((ix, el) => autoResize(el));
  });
});
