import {RemotePicker} from 'pickers/picker';

class InstitutionPicker extends RemotePicker {
  get url() { return '/api/pickers/institutions.json' }
  get resultsKey() { return 'institutions' }
  
  get pickerOptions() {
    return {
      ...super.pickerOptions,
      tags: true,
      selectOnClose: true,
      insertTag: (data, tag) => {data.push(tag)},
    };
  }
  
  templateSelection(state) {
    return state.id ? this.templateResult(state) : this.strings.placeholder;
  }
  
  templateResult(state) {
    if (!state.id) return state.text;
  
    return Components.tag('div', {class: "institution"},
      Components.tag('strong', state.text, {class: 'institution--name'}),
      (state.location ? Components.tag('span', ` (${state.location})`, {class: 'institution--location metadata'}) : ''),
    );
  }
}
InstitutionPicker.watch('.institution-picker');
