class AltParams {
  static linkClick(event, paramOverrides={}) {
    if (!event.altKey) { return; }
    
    let link = event.target.closest('a');
    
    let url = new URL(link.href);
    let altParams = JSON.parse(link.getAttribute('data-alt-params') || '{}');
    let newParams = {...altParams, ...paramOverrides};
    
    for (let [name, value] of Object.entries(newParams)) {
      url.searchParams.append(name, value);
    }
    
    event.preventDefault();
    document.location = url.toString();
    return false;
  }
}

window.addEventListener('DOMContentLoaded', () => {
  $(document).on('click', 'a[data-alt-params]', (event) => AltParams.linkClick(event));
  $(document).on('click', 'a[download]', (event) => AltParams.linkClick(event, {disposition: 'inline'}));
});
