window.addEventListener('DOMContentLoaded', function() {
  function findPopover(el) {
    return $(el.querySelector(".popover-trigger-target") || el.querySelector("[data-toggle='popover']"));
  }

  new BusyBody({
    selector: ".popover-trigger",
    added: el => {
      el.addEventListener("mouseenter", event => findPopover(event.target).popover("show"));
      el.addEventListener("mouseleave", event => findPopover(event.target).popover("hide"));
    },
  });
});
