export var ScrollSignifiers = {
  addClasses($els) {
    $els.each(function () {
      $(this)
        .toggleClass('at-top', this.scrollTop <= 0)
        .toggleClass('at-bottom', this.scrollTop + this.clientHeight >= this.scrollHeight)
        .toggleClass('at-left', this.scrollLeft <= 0)
        .toggleClass('at-right', this.scrollLeft + this.clientWidth >= this.scrollWidth);
    });
  },

  addListener(on) {
    on.on('scroll', (event) => { ScrollSignifiers.addClasses($(event.target)) });

    let $pane = on.closest('.tab-pane');
    $('a[href="#' + $pane.attr('id') + '"]').on('shown.bs.tab', () => { 
      ScrollSignifiers.addClasses($pane.find('.with-scroll-signifiers')); 
    });

    ScrollSignifiers.addClasses(on);
  }
}

// scroll events don't bubble, so we can't attach to document and need to re-attach them when the DOM changes
window.addEventListener('DOMContentLoaded', () => {
  new BusyBody({
    selector: '.with-scroll-signifiers',
    added: (el) => ScrollSignifiers.addListener($(el)),
  })
});
