export class FormSerializer {
  constructor(formElement) {
    this.form = formElement;
  }
  
  get formData() {
    return new FormData(this.form);
  }
  
  get url() {
    return new URLSearchParams(this.formData).toString();
  }
  
  get object() {
    let o = {};
    for (let [name, value] of this.formData) {
      if (o[name] !== undefined) {
        if (!o[name].push) {
          o[name] = [o[name]];
        }
        o[name].push(value || '');
      } else {
        o[name] = value || '';
      }
    }
    return o;
  }
}

window.FormSerializer = FormSerializer;
window.addEventListener('DOMContentLoaded', function() {
  $.fn.serializeObject = function() {
    return new FormSerializer(this[0]).object;
  }
});
