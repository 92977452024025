export default class ParamStore {
  constructor(namespace, defaults={}) {
    this.namespace = namespace;
    this.defaults = defaults;
  }
  
  default(key) { if (this.defaults.call) {this.defaults = this.defaults.call()}; return String(this.defaults[key]); }
  get(key) { return localStorage[`${this.namespace}.${key}`] || this.default(key); }
  set(key, value) { return localStorage[`${this.namespace}.${key}`] = value; }
}

window.ParamStore = ParamStore;
