import {RemotePicker} from 'pickers/picker';

export default class RecruitmentLevelPicker extends RemotePicker {
  get url() { return '/api/pickers/recruitment_levels.json' }
  get resultsKey() { return 'recruitment_levels' }
  get overridesAttributeName() { return 'recruitment-level-picker' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Search by job number or name',
  }}
  
  templateSelection(state) {
    return state.id ? this.templateResult(state) : this.strings.placeholder;
  }
  
  templateResult(state) {
    if (!state.id) return state.text;
  
    var $el = $("<div class='level' />")
      .append($("<span class='recruitment-name' />").text(state.recruitment_name))
      .append(" (" + state.job_number + ")");
  
    if (state.multi_level) {
      $el
        .append(", in level ")
        .append($('<em class=level-name />').text(state.name));
    }
  
    return $el;
  }
}
RecruitmentLevelPicker.watch('select.recruitment-level-picker');
