import {Components} from 'components/components';

window.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', (event) => {
    let trigger = event.target.closest('*[data-toggle=remote-modal]');

    if (trigger) {
      event.preventDefault();

      trigger.classList.remove('iconic-link--inactive');
      fetch(trigger.dataset.url)
        .finally(() => trigger.classList.add('iconic-link--inactive'))
        .then(response => response.json())
        .then(modalInfo => {
            let options = {...modalInfo};
            options.title = $('<span>').html(modalInfo.title)[0];
            options.body = $('<div>').html(modalInfo.body)[0];
            options.id = trigger.dataset.target.replace('#', '');
            if (modalInfo.subheader) options.subheader = $('<span>').html(modalInfo.subheader)[0];

            Components.Modal.confirm(options);
          }
        );
    }
  });
});
