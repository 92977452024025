import {Once} from 'behavior/once';
import {Components} from 'components/components';
import {FindAndUpdate} from 'services/find_and_update';

export class Ged {
  constructor(frame) {
    this._frame = frame;
    frame.data('ged', this);
    
    let loading = $('<div class="loading-indicator">')
      .append(Components.iconic('cog fa-spin'))
      .append(' Loading...')
      .appendTo(frame);

    this.steps();
    this._createNavigation();
    this.updateSummaries();
    this.jumpToStepWithId(new URL(document.location).searchParams.get('current-step') || frame.data('ged-current-step')).then(function() {
      frame.addClass('wizard panel-group');
      loading.remove();
    });
    
    this._attachListeners();
  }
  
  // accessors
  steps() {
    let ged = this;
    return this._steps = this._steps || this._stepElements().map(function() {
      return new Ged.Step(ged, $(this));
    });
  }
  
  currentStep() {
    return Ged.Step.containing(this._currentStepElement());
  }
  
  _stepElements() {
    return this._frame.find('.step');
  }
  
  _currentStepElement() {
    let current = this._stepElements().filter('.current');
    return current.length === 1 ? current : this._stepElements().first();
  }
  
  _hiddenField(name) {
    let form = this._frame.closest('form');
    let el = form.find('input[name="' + name + '"]');
    el = el.length ? el : $('<input type="hidden">').attr('name', name).appendTo(form);
    return el;
  }
  
  _stepList() {
    return this.steps().map(function() {return this.id();}).toArray().join(' ');
  }
  
  
  
  // saving
  _form() {
    return this._frame.closest('form')
  }
  
  validate(intention) {
    let form = this._form()[0];
    intention = intention || 'validate'

    let formData = new FormData(form);
    formData.set('steps', this._stepList());
    formData.set('intention', intention);
    this._move_file_inputs();

    return ajaxTracking.fetch(form, form.action, {
      method: form.method,
      body: formData,
      redirect: 'manual',
      logAs: `ged-${intention} from ${this.currentStep()?.id()}`,
    }).finally(() => {
      Once.reset($('.js-ged-finish'));
      Once.reset(this.currentStep()?.panel()?.find('.once'));
    }).then((response) =>
      response.json().then(json => {json.response = response; return json})
    ).then((json) => {
      FindAndUpdate.html(json.updates).then(() => {
        this._bring_back_file_inputs();
        this.updateSummaries();
        this.updateStateOfSteps(json.validated_steps);
        let step = this.currentStep();
        step.highlightErrors((errors) => {
          this._frame.trigger(`${intention}-will-complete.ged`, [this, json]);
          if (errors.length === 0 && step.panel().find('.alert.alert-danger').length === 0) {
            this.analytics(`${intention}-no-errors`, step.id());
            this._frame.trigger(`${intention}-success.ged`, [this, json]);
          } else {
            this.analytics(`${intention}-found-errors`, step.id());
            this._frame.trigger(`${intention}-fail.ged`, [this, json]);
          }
          this._frame.trigger(`${intention}-complete.ged`, [this, json]);
        });
      });

      let success = json.response.ok || json.response.redirect;

      return (success ? Promise.resolve(json) : Promise.reject(json));
    });
  }
  
  validateAndJump(step) {
    let loading = $("<p class='summary text-muted'>Validating...</p>")
      .append(Components.iconic('cog fa-spin'));
    step._panel.find(".summary").replaceWith(loading);
    this.validate().then(() => step.jumpTo());
  }
  
  save() {
    return this.validate('save').then((json) => {
      let loc = json.response.headers.get("Location") || json.location;
      if (loc) {
        document.location = loc;
      }
    });
  }
  
  updateStateOfSteps(stepIds) {
    let steps = stepIds ? '#' + stepIds.join(', #') : '*';
    
    this._stepElements().add(this._navigation.find('li'))
      .removeClass('has-errors all-complete');
    
    this.steps().filter(function() {return this.is(steps)}).each(function() {this.updateState()});
  }
  
  analytics(event, id) {
    analytics.event('ged', event, id || this._frame.attr('id'));
  }
  
  
  
  // manage current step
  jumpToStepNamed(name) {
    let $titleEl = this._frame.find('.step .panel-title:contains("' + name + '")');
    return this.jumpToStepWithId($titleEl.closest('.step').attr('id'));
  }
  
  jumpToStepWithId(id) {
    let step = $('#' + id).data('step');
    if (step) {
      return step.jumpTo().then(() => this.validate());
    } else {
      return this.currentStep().jumpTo();
    }
  }
  
  jumpToNext() {
    let step = this.currentStep().next();
    return step.jumpTo().then(() => {
      this.analytics('jump-to-step-via-next', step.id());
    });
  }
  
  jumpToPrevious() {
    let step = this.currentStep().previous();
    return step.jumpTo().then(() => {
      this.analytics('jump-to-step-via-previous', step.id());
    });
  }
  
  jumpTo(step) {
    this.analytics('jump-to-step', step.id());
    let stepElement = step.panel();
    let currentStep = this.currentStep();
    
    return $.when(
      this._jumpOff(this._stepElements().not(stepElement)),
      this._jumpOn(stepElement)
    ).then(() => {
      this.scrollToStep(stepElement);
      this._hiddenField('current_step').val(step.id());
      this._updateNavigation();
      step.focus();
      currentStep.updateSummary();
    });
  }
  
  _jumpOff(stepElement) {
    stepElement.find('.summary').slideDown();

    return $.when(
      stepElement.find('.editor-holder').slideUp()
    ).then(() => {
      if (stepElement.is('.current.has-errors')) { this.validate() }
      stepElement.removeClass('current panel-primary').addClass('panel-default');
    });
  }
  
  _jumpOn(stepElement) {
    return $.when(
      stepElement.find('.editor-holder').slideDown(),
    ).then(function() {
      stepElement.addClass('current panel-primary');
    });
  }
  
  scrollToStep(stepElement) {
    stepElement.find('.editor').one('transitionend', function() {
      $('html, body').animate({
        scrollTop: stepElement.offset().top + 'px'
      }, 'fast');
    });
  }
  
  
  
  // summaries
  updateSummaries() {
    this.steps().map(function() {this.updateSummary()});
  }
  
  

  // private methods
  _createNavigation() {
    this._navigation = $('#' + this._frame.attr('id') + '-navigation')
      .addClass('ged-navigation hidden-xs');
    
    let nav = this._navigation.find('ul.nav');
    this._stepElements().map(function() {
      let item = $('<li>').appendTo(nav);
      $(this).data('navigation-item', item);
      
      $('<a>')
        .attr('href', '#' + $(this).attr('id'))
        .html($(this).find('.panel-title').html())
        .data('step', $(this).data('step'))
        .data('step-element', $(this))
        .appendTo(item);
    });
  }
  
  _attachListeners() {
    // navigation
    this._frame.on('click', '.step:not(.current, :last)', (event) => {
      event.preventDefault();
      let step = Ged.Step.containing($(event.target));
      this.analytics('jump-to-step-via-click', step.id());
      step.jumpTo();
    });

    this._frame.on('click', '.step:not(.current):last', (event) => {
      event.preventDefault();
      let step = Ged.Step.containing($(event.target));
      this.analytics('jump-to-step-via-click', step.id());
      this.validateAndJump(step);
    });

    this._frame.on('keydown', '.step', (event) => {
      if (event.target !== event.currentTarget) return;
      if (event.which !== 13 && event.which !== 32) return; // respectively `ENTER` and `SPACE` keys
      let step = Ged.Step.containing($(event.target));
      this.analytics('jump-to-step-via-keyboard', step.id());
      step.jumpTo();
    });
    
    this._frame.on('click', '.step .js-ged-next', (event) => {
      event.preventDefault();
      this.validate().then(() => this.jumpToNext());
    });
    this._frame.on('click', '.step .js-ged-previous', (event) => {
      event.preventDefault();
      this.validate().finally(() => this.jumpToPrevious());
    });
    this._frame.on('click', '.step .js-ged-finish', (event) => {
      event.preventDefault();
      Once.disable($(event.target));

      this.save();
    });
    this._frame.on('click', '.step .js-ged-cancel', (event) => {
      event.preventDefault();
      
      Components.Modal.confirm({
        id: this._frame.attr('id') + '-cancel-confirm',
        class: 'cancel-confirmation',
        title: 'Are you sure?',
        body: "Are you sure you want to cancel and leave this page? All progress will be lost and cannot be retrieved. If you return, you will have to start again from the beginning.",
        confirm: 'Yes, leave this page',
        confirmClass: 'btn-danger',
        cancel: 'No, continue editing',
        whenConfirmed: (modal) => {
          let url = $(event.target).attr('href');
          url = url === '#' ? this._frame.data('ged-back-url') : url;
          if (url) {
            document.location = url;
          } else {
            history.back();
          }
        },
      });
    });
    this._navigation.on('click', 'a:not(:last)', (event) => {
      event.preventDefault();
      let step = $(event.target).data('step');
      step.jumpTo();
      this.analytics('jump-to-step-via-navigation', step.id());
    });

    this._navigation.on('click', 'a:last', (event) => {
      event.preventDefault();
      let step = $(event.target).data('step');
      this.validateAndJump(step);
      this.analytics('jump-to-step-via-navigation', step.id());
    });
  }
  
  _updateNavigation() {
    let currentStepId = this._currentStepElement().attr('id');
    let activeLink = this._navigation.find('a[href="#' + currentStepId + '"]');
    this._navigation.find('li.active').removeClass('active');
    activeLink.closest('li').addClass('active');
    
    this.steps().each(function() { this.updateNavigationButtons() });
  }

  _move_file_inputs() {
    $('input[type=file]').map(function() {
      $(this).clone().appendTo($(this).parent());
      $('body').append($(this).hide());
    });
  }

  _bring_back_file_inputs() {
    $('input[type=file]:hidden').map(function() {
      $("#" + $(this).attr('id')).replaceWith($(this).show());
    });
  }
}
  
Ged.Step = class GedStep {
  constructor(ged, panel) {
    this._ged = ged;
    this._panel = panel;
  
    panel.data('step', this);
  
    this._addIcons();
    this._wrapEditor();
    this._createNavigationButtons();
  }
  
  static containing(el) {
    return $(el).closest('.step').data('step');
  }
  
  static makeNavigationButton(beforeText, afterText, direction) {
    return $('<button>')
      .addClass('btn iconic-button spin-while-disabled once')
      .append($('<span class="title">').text(beforeText))
      .append(Components.iconic('spinner shown-when-disabled'))
      .append(Components.iconic('chevron-circle-' + direction))
      .append(afterText);
  }
  
  static nextButton(text) {
    return Ged.Step.makeNavigationButton(text || 'Next', '', 'right').addClass('js-ged-next btn-primary icon-after');
  }
  
  static previousButton() {
    return Ged.Step.makeNavigationButton('', 'Previous', 'left').addClass('js-ged-previous btn-default');
  }
  
  static cancelLink() {
    return $('<a href="#" class="js-ged-cancel">').text("Cancel");
  }
  
  is(sel) {
    return this._panel.is(sel);
  }
  
  panel() {
    return this._panel;
  }
  
  title() {
    return this._panel.find('h3').text();
  }
  
  id() {
    return this._panel.attr('id');
  }
  
  previous() {
    return Ged.Step.containing(this._panel.prevAll().filter(this._ged._stepElements()).first());
  }
  
  next() {
    return Ged.Step.containing(this._panel.nextAll().filter(this._ged._stepElements()).first());
  }
  
  errors() {
    return this._panel.find('.has-error');
  }
  
  _addIcons() {
    this._panel.find('.panel-heading h3')
      .prepend(Components.iconic('circle step-state shown-when-pending'))
      .prepend(Components.iconic('exclamation-circle step-state shown-when-has-errors'))
      .prepend(Components.iconic('check-circle step-state shown-when-all-complete'));
  }
  
  _wrapEditor() {
    let panelBody = this._panel.find('.panel-body');
    $('<div class="editor-holder">')
      .appendTo(panelBody)
      .append($(panelBody).find('.editor, .button-bar'));
  }
  
  _createNavigationButtons() {
    let $holder = this._panel.find('.editor-holder');
    let $buttonBar = $holder.find('.button-bar');
    $buttonBar = $buttonBar.length ? $buttonBar : $('<div class="button-bar">').appendTo($holder);
    
    let prevStep = this.previous();
    let nextStep = this.next();
    if($buttonBar.find('button.js-ged-finish')) {
      Ged.Step.previousButton().appendTo($buttonBar);
    } else {
      Ged.Step.previousButton().prependTo($buttonBar);
    }
    Ged.Step.nextButton().prependTo($buttonBar);
    $buttonBar.find('button').wrapAll('<div class="navigation-bar">')
    Ged.Step.cancelLink().appendTo($buttonBar);
  }
  
  _attachListeners() {
    this._frame.on('blur change', '.step .editor', (event) => {
      this.updateSummary();
    });
  }
  
  jumpTo() {
    return this._ged.jumpTo(this);
  }
  
  updateNavigationButtons() {
    let nextStep = this.next();
    this._panel.find('.js-ged-next .title').text(nextStep ? 'Next: ' + nextStep.title() : '');
  }
  
  focus() {
    this._panel.find(':input:not(.sr-only)').first().focus();
  }
  
  highlightErrors(then) {
    let errors = this._ged.currentStep().errors().effect('highlight', {color: '#fdd'});
    then && then(errors);
  }
  
  updateState() {
    let $step = $(this._panel);
    if ($step.is('.stateless')) return;
    
    let hasErrors = $step.find('.has-error').length > 0;

    $('.js-ged-finish').toggleClass('disabled', $('.alert-danger').length > 0);

    let state = hasErrors ? 'error' : 'complete';
    let oldState = $step.data('state');
    if (state !== oldState) {
      $step.data('state', state);
      this._ged.analytics('step-state-' + state, this.id());
      this._ged.analytics('step-state-change-' + [oldState || 'pending', state].join('-to-'), this.id());
    }
    
    $step.add($step.data('navigation-item'))
      .toggleClass('has-errors', hasErrors)
      .toggleClass('all-complete', !hasErrors);
  }
  
  updateSummary() {
    let $editor = this._panel.find('.editor');
    let $editorHolder = $editor.closest('.editor-holder');
    let $step = this._panel;
    
    $step.find('.summary').remove();
    let isCurrent = $editor.closest('.step.current').length !== 0;
    let $summary = $('<dl class="summary dl-horizontal tight">')
      .insertAfter($editorHolder);
  
    let addLine = function($formGroup, definition, cssClass) {
      let labels = $formGroup.find('label.control-label');
      let label = labels.length === 1 ? labels.first() : $formGroup;
      
      let term = label.attr('data-short-label') || label.text().replace(/^\* /, '');
      if (!term) {return}
      
      $('<dt>').addClass(cssClass).text(term).appendTo($summary);
      $('<dd>').addClass(cssClass).text(definition).appendTo($summary);
    };
    
    $editor
      .find('.form-group, [data-ged-summary-item]')
      .filter(':not([disabled])')
      .filter(function() { return $(this).closest('fieldset[disabled]').length === 0})
      .each(function()
    {
      let $formGroup = $(this);
      let $input = $formGroup.find(':input').not('.select2-input, *[type="hidden"]');
      
      if ($formGroup.is('[data-ged-summary-item]')) {
        return addLine($formGroup, null, $formGroup.data('ged-summary-item'));
      }
      
      if ($formGroup.closest('[disabled], .disabled').length) {
        return;
      }
      
      let val = $input.val();
      if ($input.is('select')) {
        val = $.map($input.find('option:selected:not([value=""])'), function(el) {return el.text}).join(', ');
      } else if ($input.is(':radio')) {
        val = $input.filter(':checked').closest('label').text();
      } else if ($input.is('input[type=checkbox]')) {
        val = $.map($input.filter(':checked'), function(el) {return $(el).closest('label').text()}).join(', ');
      } else if ($input.is('input[type=password]')) {
        val = val ? ($input.data('ged-summary-item-value') || 'Not displayed') : ''
      }

      if ($input.is('input[type=file]')) {
        val = val.split(/\/|\\/).pop();
      }
      
      if($input.data('ged-summary-item-hidden') !== true) {
        val && addLine($formGroup, val);
      }
      
      $formGroup.find('.help-block.error').map(function() {
        addLine($formGroup, $(this).text(), 'error');
      });
    });
    
    if ($summary.find('dt:not(.header)').length === 0) {
      $summary.remove();
      $('<p class="summary text-muted">')
        .text('Pending. Click to fill out now.')
        .toggleClass("hidden", isCurrent)
        .toggleClass("shown", isCurrent)
        .insertAfter($editorHolder);
    }
  }
}

window.addEventListener('DOMContentLoaded', function() {
  window.Ged = Ged;
  
  $('*[data-ged="true"]').each(function() {new Ged($(this))});
});
