import {FindAndUpdate} from 'services/find_and_update';
import {Components} from 'components/components';
import Rails from "@rails/ujs";

export class BatchIndicator {
  constructor(el) {
    this.el = el;
    this.waitTime = Number(el.getAttribute('data-wait-time') || BatchIndicator.defaultWaitTime);
  }
  
  static get defaultWaitTime() { return document.querySelector('html.test') ? 1 : 5000; }
  
  get icon() { return this.el.querySelector('.iconic'); }
  get completeIcon() { return Components.iconic("check icon-success"); }
  get failedIcon() { return Components.iconic("exclamation-circle icon-danger"); }
  get batchStatusUrl() { return this.el.getAttribute('data-batch-status-url'); }
  get analyticsId() { return this.el.getAttribute('data-analytics-id') || this.el.getAttribute('id') || this.el.getAttribute('data-batch-id'); }
  
  poll() {
    this.timeout = this.timeout || setTimeout(() => {this.checkBatch()}, this.waitTime);
  }
  
  unpoll() {
    clearTimeout(this.timeout);
  }
  
  checkBatch() {
    this._analytics('check');
    fetch(this.batchStatusUrl)
      .then(response => response.json())
      .then(status => {
        this._setDataAttributes(status);
        
        if (status.failures > 0) {
          this._analytics('failed');
          this.onFail(status);
        } else if (status.pending > 0) {
          this.onPending(status);
          delete this.timeout;
          this.poll();
        } else {
          this._analytics("complete");
          this.onComplete(status);
        }
      });
  }
  
  onFail(status) {
    this._setFailedIcon();
  }
  
  onComplete(status) {
    this._setCompleteIcon();
    this._fetchUpdates();
    this._redirect();
  }
  
  onPending(status) {
    // nothing to do
  }
  
  _setDataAttributes(status) {
    status.complete = (status.total - status.pending);
    status.percent = Math.round(status.complete / status.total * 10000, 1) / 100;
    
    this.el.setAttribute("data-status-total", status.total);
    this.el.setAttribute("data-status-pending", status.pending);
    this.el.setAttribute("data-status-complete", status.complete);
    this.el.setAttribute("data-status-percent", status.percent);
    this.el.setAttribute("title", `${status.percent}% complete…`);
  }
  
  _setFailedIcon() {
    this.icon.replaceWith(this.failedIcon);
  }
  
  _setCompleteIcon() {
    this.icon.replaceWith(this.completeIcon);
  }
  
  _fetchUpdates() {
    let updatesUrl = this.el.getAttribute('data-batch-updates-url') || this.el.getAttribute('href');
    if (!updatesUrl) { return; }
    
    if (this.el.matches('a[data-remote]')) {
      $(this.el).on('ajax:complete', e => this.el.remove());
      Rails.fire(this.el, "click");
    } else {
      fetch(updatesUrl)
        .then(response => response.json())
        .then(updates => FindAndUpdate.all(updates));
    }
  }
  
  _redirect() {
    let destinationUrl = this.el.getAttribute('data-batch-redirect-url');
    if (!destinationUrl) { return; }
    
    document.location.href = destinationUrl;
  }
  
  _analytics(event) {
    analytics.event('batch-indicator', event, this.analyticsId);
  }
}

window.BatchIndicator = BatchIndicator;

window.addEventListener('DOMContentLoaded', function() {
  new BusyBody({
    selector: '.batch-indicator',
    added: (e) => new BatchIndicator(e).poll(),
  });
});

