import {SearchSourcePicker} from 'pickers/search_source_picker';

class AdSourcePicker extends SearchSourcePicker {
  get strings() { return {
    ...super.strings,
    placeholder: 'Type an ad source',
  }}

  processResult(searchSource) {
    return {text: searchSource.name, id: searchSource.name};
  }
}
AdSourcePicker.watch('.ad-source-picker');
