import Rails from '@rails/ujs';
import {Components} from 'components/components';

let modalId = 'ujs-confirmation';
Rails.confirm = () => true;
Rails.delegate(document, '*', 'confirm', event => {
  let element = event.target;
  let message = element.getAttribute('data-confirm');

  if (document.querySelector(`#${modalId}.in`)) {
    return true; // modal is open and user clicked to confirm
  }

  let data = element.dataset;
  Components.Modal.confirm({
    id: modalId,
    analyticsId: data.confirmAnalyticsId || data.analyticsId || message,
    class: `confirm-modal ${data.confirmClass || ''}`,
    title: data.confirmTitle || 'Are you sure?',
    body: data.confirmHtml === 'true' ? ((b) => {b.innerHTML = message}) : message,
    confirm: data.confirmButton,
    confirmClass: data.confirmButtonClass,
    confirmData: JSON.parse(data.confirmData || '{}'),
    whenConfirmed: (modal) => {
      element.click && element.click();
    },
  });

  return false;
});
