export default class CopyButton {
  constructor(el) {
    this.el = el;
  }

  setup() {
    if (!navigator.clipboard) { return }

    this.el.addEventListener('click', e => this.doCopy(e))
    this.el.dataset.copySupported = true;
  }

  doCopy(event) {
    event.preventDefault();
    navigator.clipboard?.writeText(this.textToCopy);

    let message = "Copied!";
    let tip = $(this.el).data('bs.tooltip');
    let savedTitle = this.el.dataset.originalTitle || '';
    let savedOptions = tip?.options;

    if (tip) { // existing tip; update content
      tip.tip().find('.tooltip-inner').text(message);
    } else { // no tip; show a new one
      $(this.el)
        .tooltip({title: message})
        .tooltip("show");
    }
    setTimeout(() => {
      $(this.el).tooltip("hide");
      this.el.dataset.originalTitle = savedTitle;
      $(this.el).tooltip(savedOptions || "destroy");
    }, 1000);
  }

  get textToCopy() {
    return this.el.dataset.copy;
  }
}

window.addEventListener('DOMContentLoaded', function() {
  new BusyBody({
    selector: '[data-copy]',
    added: el => new CopyButton(el).setup(),
  })
});
