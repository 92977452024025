import {RemotePicker} from 'pickers/picker';

class ExemptionPicker extends RemotePicker {
  get url() { return '/api/pickers/exemptions.json' }
  get resultsKey() { return 'exemptions' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Search by EXR# or name',
  }}
  
  processResult(hp) {
    return {text: hp.candidate_name, id: hp.code, hp: hp};
  }
}
ExemptionPicker.watch('.exemption-picker');
