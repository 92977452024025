export default class ActiveTab {
  constructor(tabKey) {
    this.tabKey = tabKey;
  }
  
  navTabs() {
    return $(`.nav-tabs[data-save-active-tab-as="${this.tabKey}"]`);
  }
    
  links() {
    return this.navTabs().find('li > a');
  }
    
  linkFor(value) {
    return this.links().filter(`a[href="#${value}"]`);
  }
    
  defaultLink() {
    return this.links().first();
  }
  
  valueForLink(link) {
    return (link.attr('href') || '').replace('#', '');
  }
    
  urlFor(value) {
    var link = this.linkFor(value);
    if (link.data('url')) return link.data('url');
  
    var url = new URL(document.location);
    if (document.querySelector(`${url.hash}.modal`)) { url.hash = '' }
    url.searchParams.set(tabKey, value);
    
    return url.toString();
  }
    
  get() {
    return (history.state && history.state.newTab) || this.valueForLink(this.defaultLink());
  }
  
  set(value) {
    if (history.state && history.state.newTab === value) return;
    
    var state = {newTab: value, tabKey: this.tabKey};
    var newUrl = this.urlFor(value);
    newUrl && history.pushState(state, null, newUrl);
    this.navTabs().trigger('change.active-tab', state);
  }
  
  setUp() {
    var activeLink = this.links().closest('li.active').find('a');
    if (activeLink.length) {
      history.replaceState({
        ...(history.state || {}),
        newTab: this.valueForLink(activeLink),
        tabKey: this.tabKey,
      }, null, activeLink.data('url') + this.currentSearchParams() + document.location.hash);
    }
    return this;
  }

  currentSearchParams() {
    let params = new URL(document.location).searchParams.toString();
    return params ? `?${params}` : '';
  }
}

window.addEventListener('DOMContentLoaded', function() {
  var listenersByTabKey = {};
  
  $('.nav-tabs[data-save-active-tab-as]').each(function() {
    var saveAs = $(this).attr('data-save-active-tab-as');
    var listener = new ActiveTab(saveAs).setUp();
    
    listenersByTabKey[saveAs] = listener;
  });
  
  $(document).on('shown.bs.tab', '.nav-tabs[data-save-active-tab-as] > li > a', function(event) {
    var navTabs = $(this).closest('.nav-tabs');
    var saveAs = navTabs.attr('data-save-active-tab-as');
    var listener = listenersByTabKey[saveAs];
    var toSave = listener.valueForLink($(this));
    
    listener.set(toSave);
  });
  
  $(window).on("popstate", function(e) {
    var state = e && e.originalEvent && e.originalEvent.state;
    if (!state || !state.tabKey) return;
    
    listenersByTabKey[state.tabKey]
      .linkFor(state.newTab)
      .tab('show');
  });
  
  ActiveTab.AccessKey = {
    addTo: function($el) {
      $el.find('*[data-active-tab-accesskey]').each(function() {
        $(this).attr('accesskey', $(this).attr('data-active-tab-accesskey'));
      });
    },
    removeFrom: function($el) {
      $el.find('*[data-active-tab-accesskey]').removeAttr('accesskey');
    }
  };
  
  $(document).on('shown.bs.tab', function(event) {
    ActiveTab.AccessKey.removeFrom($($(event.relatedTarget).attr('href')));
    ActiveTab.AccessKey.addTo($($(event.target).attr('href')));
  });
  
  $('.tab-pane.active').each(function() { ActiveTab.AccessKey.addTo($(this)); });
});
