import { Components } from 'components/components';
import { BootstrapInputGroup } from 'bootstrap/input_groups';
import { Picker } from 'pickers/picker';

function updateDatetimeIn($el) {
  if (!$el || !$el.length || !$el.find) return;
  
  var dateInput = $el.find('input.datepicker');
  var timeInput = $el.find('input.timepicker');
  var sanitizedTime = timeInput.val().replace(/ ?AM/, 'am').replace(/ ?PM/, 'pm').replace(/^\s*0/, '');
  $el.find('input[type=hidden]').val(dateInput.val() + " " + sanitizedTime);
}

function inlineTrigger($el) {
  var trigger = $el.next();
  new BootstrapInputGroup($el[0], {
    after: Components.htmlTree('<span class="input-group-btn"></span>').firstChild,
    then: (group) => { group.querySelector('.input-group-btn').append(trigger[0]) },
  });
}

class DatePicker extends Picker {
  get pickerOptions() {
    let $el = $(this.el);
    
    return {
      ...super.pickerOptions,
      showOn: 'both',
      buttonText: '<span class="fa-regular fa-calendar-alt"></span>',
      dateFormat: $el.attr('data-format'),
      onSelect: function() {
        $el[0].dispatchEvent(new CustomEvent("change", {bubbles: true, cancelable: true}));
      }
    };
  }
  
  get trigger() {
    return this.el.nextSibling.matches('.ui-datepicker-trigger') ? this.el.nextSibling : this.el.nextSibling.querySelector('.ui-datepicker-trigger');
  }
  
  setup() {
    var $el = $(this.el);
    $el.datepicker(this.pickerOptions);
  
    if ($el.hasClass('input-sm')) {
      $el.parent().find('.ui-datepicker-trigger').addClass("input-sm");
    }
    
    $el.change(function() {
      updateDatetimeIn($(this).closest('.jquery_datetime_picker'));
    });
  
    inlineTrigger($el);
    
    this.enableDisableTrigger();
    this.watchDisabled();
  }
  
  enableDisableTrigger() {
    this.trigger.disabled = this.el.disabled || this.el.readOnly;
  }
  
  watchDisabled() {
    if (this._observer) { return }
    
    this._observer = new MutationObserver(() => this.enableDisableTrigger());
    this._observer.observe(this.el, {attributeFilter: ['disabled', 'readonly']});
  }
}
DatePicker.watch("input.datepicker:not(.hasDatepicker)");

let ixTimePicker = 0;
class TimePicker extends Picker {
  addTriggerAfter($el) {
    let id = 'timepicker-trigger-' + ixTimePicker++;
    let $btn = $('<button class="ui-timepicker-trigger" />').
      append(Components.iconic("clock")).
      attr('id', id);
    $el.after($btn);
    return id;
  }
  
  setup() {
    let $el = $(this.el);
    let triggerId = this.addTriggerAfter($el)

    $el.timepicker({
      showPeriod: true,
      showLeadingZero: false,
      periodSeparator: '',
      amPmText: ['am', 'pm'],
      showOn: 'both',
      button: `#${triggerId}`,
      onSelect: function() {
        $(this).timepicker('hide');
      }
    });

    $el.change(function() {
      updateDatetimeIn($(this).closest('.jquery_datetime_picker'));
    });
  
    inlineTrigger($el);
  }
}
TimePicker.watch('input.timepicker:not(.hasTimepicker)');
