window.addEventListener('DOMContentLoaded', function() {
  $(document).on('show.bs.popover', function(event) {
    var target = $(event.target);
    var classes = target.data('popover-class');
    var popoverEl = target.data('bs.popover').tip();
    if (popoverEl && classes) {
      popoverEl.addClass(classes);
    }
  });
});
