export default class ModalReplanting {
  constructor(root) {
    this.root = root;
    this.rootId = this.root.getAttribute('id');
  }
  
  get replantInSelector() {
    let dataVal = this.root.getAttribute('data-replant');
    return ['', 'true'].includes(dataVal) ? 'body' : dataVal;
  }
  get replantIn() { return document.querySelector(this.replantInSelector); }
  get replaces() { return this.rootId ? document.querySelector(`${this.replantInSelector} > *[id="${this.rootId}"]`) : null; }
  
  replant() {
    this.replaces?.remove();
    this.replantIn.appendChild(this.root);
  }
}

window.addEventListener('DOMContentLoaded', function() {
  $(document).on('show.bs.modal', '.modal', function(event) {
    let replantRoot = event.target.closest('*[data-replant]');
    if (replantRoot) {
      new ModalReplanting(replantRoot).replant();
    }
  });
});
