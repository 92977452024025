import { Modal } from 'components/modal';
import { AjaxSpinnerLink } from 'components/ajax_spinner_link';

export class Components {
  static htmlTree(source) {
    let div = document.createElement('div');
    div.innerHTML = source;
    return div;
  }
  
  static tag(tagName, ...parts) {
    let el = tagName.tagName ? tagName : document.createElement(tagName);
    
    parts.forEach(part => {
      if (part === null || part === undefined) {
        // ignore
      } else if ("string boolean number".split(' ').includes(typeof part) || part.tagName) {
        el.append(part);
      } else if (Array.isArray(part)) {
        Components.tag(el, ...part);
      } else if ("function" === typeof part) {
        Components.tag(el, part.call(el, el));
      } else {
        Components.tag.setAttributes(el, part);
      }
    })
    
    return el;
  }
  
  static iconic(name, ...parts) {
    return Components.tag('span', {class: `iconic fa fa-${name}`}, ...parts);
  }
  
  static iconicLink(iconName, ...parts) {
    return Components.tag('a', {href: '#', class: 'iconic-link'}, Components.iconic(iconName), ...parts);
  }
  
  static iconicButton(iconName, text, ...parts) {
    return Components.tag("button", Components.iconic(iconName), text, ...parts, {class: "btn iconic-button"});
  }
  
  static iconicText(iconName, text, ...parts) {
    return Components.tag("div",
      {class: 'iconic-text'},
      Components.iconic(iconName),
      Components.tag("div", {class: 'iconic-text-content'}, text),
      ...parts,
    );
  }
  
  static helptip(text, options, ...parts) {
    return Components.tag(
      Components.iconic(options?.icon || 'question-circle'),
      {...(options || {}), icon: null},
      {title: text, 'data-toggle': 'tooltip'},
      ...parts);
  }
  
  static alert(contextualState, options, ...parts) {
    return Components.tag('div',
      {class: `alert with-icon alert-${contextualState}`},
      {...options, icon: null, title: null, text: null},
      Components.tag('div', {class: 'alert-parts'},
        Components.iconic(options.icon || {info: 'info-circle', success: 'check-circle'}[contextualState] || 'exclamation-triangle', {class: 'fa-2x fa-fw'}),
        Components.tag('div', {class: 'alert-content'},
          Components.tag('strong', {class: 'title'}, options.title),
          Components.tag('span', {class: 'text'}, options.text),
        )
      ),
      ...parts,
    )
  }
  
  static Modal = Modal;
  static AjaxSpinnerLink = AjaxSpinnerLink;
};

Components.tag.setAttributes = function(el, attributes) {
  for (let [name, value] of Object.entries(attributes || {})) {
    if (value !== null && value !== undefined) {
      if (name == "class") { value = [el.className, value].join(' ').trim() }
      
      el.setAttribute(name, value);
    }
  }
  return el;
};

window.Components = Components;
