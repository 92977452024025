import {RemotePicker} from 'pickers/picker';

class RecruitmentPicker extends RemotePicker {
  get url() { return '/api/pickers/recruitments.json' }
  get resultsKey() { return 'recruitments' }
  get strings() { return {
    ...super.strings,
    placeholder: 'Search by job number or name',
  }}

  templateResult(obj) {
    if(!obj.hp) return obj.text;
    let recruitmentNameEl = document.createElement('span');
    recruitmentNameEl.textContent = `${obj.text} (${obj.id})`;
    recruitmentNameEl.classList.add('recruitment-name');
    recruitmentNameEl.dataset.multiLevel = obj.multi_level;
    return recruitmentNameEl;
  }

  processResult(hp) {
    return {text: hp.name, id: hp.job_number, multi_level: hp.multi_level, hp: hp};
  }
}
RecruitmentPicker.watch('.recruitment-picker');
