import Rails from "@rails/ujs";
import jQuery from 'jquery';

Rails.start();

jQuery.ajaxPrefilter(function (options, originalOptions, xhr) {
  if (!options.crossDomain) {
    return Rails.CSRFProtection(xhr);
  }
});
