import i18next from 'i18next'; // https://www.i18next.com/overview/introduction
import Backend from 'i18next-http-backend'; // https://github.com/i18next/i18next-http-backend

export default class Translations {
  constructor(i18nextInstance) {
    this._i18nextInstance = i18nextInstance;
  }

  t(key, options) {
    return this._i18nextInstance.t(key, options);
  }

  static async create(namespaces) {
    let i18nextInstance = await this.setupI18nextInstance(namespaces);
    return new Translations(i18nextInstance);
  }

  static async setupI18nextInstance(namespaces) {
    return await i18next
      .use(Backend)
      .init({
        backend: {
          loadPath: `/api/internal/translations.json`,
          queryStringParams: { namespaces: namespaces }
        },
        interpolation: {
          prefix: '%{',
          suffix: '}'
        }
      })
      .then(() => {
        return i18next.cloneInstance();
      });
  }
}
