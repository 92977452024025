export class DynamicErrorArea {
  constructor(root) {
    this._root = root;
  }
  
  get root() {
    return this._root;
  }
  
  get errorsAlert() {
    return this.root.querySelector('.alert-danger.could-not-save');
  }
  
  fill(errors) {
    errors = errors || {};
    this.root.dataset.lastErrors = errors;
    
    this.clear();
    this.fillErrorAlert(errors);
    this.fillFormFields(errors);
    Once.resetWithin(this.root);
  }
  
  fillErrorAlert(errors) {
    let err = this.errorsAlert;
    if (!err) { return }
    
    let errTitle = err.querySelector('.title');
    if (errTitle) { errTitle.innerText = errors.error_title || '' }
    
    let errText = err.querySelector('.text');
    if (errText) {
      let message = errors.error_message || "An error occurred.";
      message = errors.html ? Components.htmlTree(message) : message;
      errText.innerText = '';
      Components.tag(errText, message);
    }
    
    err.classList.remove('hidden');
    err.classList.remove('starts-hidden');
  }
  
  fillFormFields(errors) {
    let leftovers = [];
    for (let [field, messages] of Object.entries(errors.field_errors || {})) {
      let selector = `.form-group.${field}`;
      let group = this.root.matches(selector) ? this.root : this.root.querySelector(selector);
      let controls = group?.querySelector('.controls');
      
      group?.classList?.toggle('has-error', messages.length || controls.querySelector('.help-block.error'));
      
      if (controls) {
        let controlsHint = controls?.querySelector('.help-block');
        messages.forEach(message => {
          let helpBlock = Components.tag('span', {class: 'help-block error'}, message);
          if (controlsHint) {
            controls.insertBefore(helpBlock, controlsHint);
          } else {
            Components.tag(controls, helpBlock);
          }
          if (group.length == 0) {leftovers.push({field: field, message: this})}
        });
      }
    }
    
    this.fillLeftovers(leftovers);
  }
  
  fillLeftovers(leftovers) {
    this.errorsAlert?.querySelector('ul.leftover-errors')?.remove();
    if (leftovers.length > 0) {
      let leftoversList = Components.tag('ul', {class: 'leftover-errors starts-hidden'});
      leftovers.forEach((leftover) => {
        Components.tag(leftoversList, Components.tag('li', `${leftover.field} ${leftover.message}`));
        this._analytics("leftover-" + leftover['field']);
      });
      this.errorsAlert?.appendChild(leftoversList);
    }
  }
  
  clear() {
    let r = this.root;
    
    r.querySelector('.alert-danger')?.classList?.add('hidden');
    r.querySelectorAll('.form-group').forEach(fg => fg.classList.remove('has-error'));
    r.querySelectorAll('.help-block.error').forEach(err => err.remove());
  }
  
  _analytics(event) {
    analytics.event('dynamic-error-area', event, this.root.attr('data-analytics-id') || this.root.attr('id'));
  }
  
  static setup() {
    $(document).on('ajax:complete', '*[data-dynamic-errors]', function(event) {
      let [xhr, status] = event.detail;
      new DynamicErrorArea(event.currentTarget).fill(xhr.responseJSON || JSON.parse(xhr.responseText));
    });
  }
}

window.DynamicErrorArea = DynamicErrorArea;
window.addEventListener('DOMContentLoaded', function() {
  DynamicErrorArea.setup();
});
